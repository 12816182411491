import React, { useEffect, useState } from 'react';

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import ProtectedRouter from '../components/routes/ProtectRouter';

import {
  Account,
  AddBalance,
  AddCompany,
  AddCompanyPeople,
  AnalyzeMovement,
  Anticipation,
  Balances,
  BankSlip,
  Collaborators,
  CreateAccount,
  Customers,
  Dashboard,
  Driver,
  Extract,
  FormCostumer,
  GroupOnboarding,
  InfoProfile,
  Invoice,
  Login,
  Order,
  PasswordReset,
  Payment,
  Profile,
  RecoverDocument,
  RecoverGetToken,
  RecoverToken,
  ResetPassword,
  SearchOrder,
  Transfer,
  ViewCompany,
  ViewContract,
  ViewMobileContract,
  Vouchers,
} from '../pages';

import Header from '../components/Header';
import TabMenu from '../components/TabMenu';
import { useUser } from '../hooks/useUser';
import SignUp from '../pages/SignUp';

import { Limits } from '../pages/Limits';
import Linked from '../pages/Linked';
import { Onboarding } from '../pages/Onboarding';
import ViewProof from '../pages/ViewProof';
import CreateSurveyForm from '../pages/Surveys';
import useSurveys from '../hooks/useSurveys';

const payment = [
  {
    path: '/menu/collaborator/:id',
    permissions: [46, 44],
    main: () => <Driver />,
  },
  {
    path: '/menu/proof/:movementKey',
    permissions: [5],
    main: () => <ViewProof />,
  },
  {
    path: '/menu/analyze',
    permissions: [25],
    main: () => <AnalyzeMovement />,
  },
  {
    path: '/menu/company/:companyId',
    permissions: [],
    main: () => <ViewCompany />,
  },
  {
    path: '/menu/group-customer',
    permissions: [],
    main: () => <GroupOnboarding />,
  },
  {
    path: '/menu/contas',
    permissions: [46, 44],
    main: () => <Collaborators />,
  },
  {
    path: '/menu/linked',
    permissions: [46, 44],
    main: () => <Linked />,
  },
  {
    path: '/menu/surveys',
    permissions: [1],
    main: () => <CreateSurveyForm />,
  },
  {
    path: '/menu/limits',
    permissions: [58],
    main: () => <Limits />,
  },
  {
    path: '/menu/balances',
    permissions: [],
    main: () => <Balances />,
  },
  {
    path: '/menu/extract/:id',
    permissions: [5],
    main: () => <Extract />,
  },
  {
    path: '/menu/add-company-people',
    permissions: [],
    main: () => <AddCompanyPeople />,
  },
  {
    path: '/menu/add-company',
    permissions: [],
    main: () => <AddCompany />,
  },
  {
    path: '/menu/account',
    permissions: [],
    main: () => <Account />,
  },
  {
    path: '/menu/order/:id',
    permissions: [15],
    main: () => <Order />,
  },
  {
    path: '/menu/consignment/order/:id',
    permissions: [],
    main: () => <Order />,
  },
  {
    path: '/menu/search/:id',
    permissions: [],
    main: () => <SearchOrder />,
  },
  {
    path: '/menu/consignment',
    permissions: [16],
    main: () => <Anticipation />,
  },
  {
    path: '/menu/transfer',
    permissions: [16],
    main: () => <Transfer />,
  },
  {
    path: '/menu/invoice',
    permissions: [69],
    main: () => <Invoice />,
  },
  {
    path: '/menu/bank-slip',
    permissions: [],
    main: () => <BankSlip />,
  },
  {
    path: '/menu/payment',
    permissions: [],
    main: () => <Payment />,
  },
  {
    path: '/menu/customers/edit',
    permissions: [],
    main: () => <FormCostumer />,
  },
  {
    path: '/menu/customers',
    permissions: [],
    main: () => <Customers />,
  },
  {
    path: '/menu/vouchers',
    permissions: [],
    main: () => <Vouchers />,
  },
  {
    path: '/menu/profile',
    permissions: [],
    main: () => <Profile />,
  },
  {
    path: '/menu/info-profile',
    permissions: [],
    main: () => <InfoProfile />,
  },
  {
    path: '/menu/password',
    permissions: [],
    main: () => <PasswordReset />,
  },
  {
    path: '/menu/dashboard',
    permissions: [],
    main: () => <Dashboard />,
  },
  {
    path: '/menu/added_balance',
    permissions: [],
    main: () => <AddBalance />,
  },
  {
    path: '/menu/account-customer',
    permissions: [],
    main: () => <Onboarding />,
  },
  {
    path: '/menu/create-account',
    permissions: [],
    main: () => <CreateAccount />,
  },
];

const Routes = () => {
  const history = useHistory();

  const [state, setState] = useState(null);
  const { pathname } = useLocation();
  const { fetchSurveys } = useSurveys();
  const { autoLogin } = useUser();

  useEffect(() => {
    setState(history.location);
    autoLogin(pathname, state);
    fetchSurveys();
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route exact path={'/'} component={Login} />
      <Route path={'/reset'} component={ResetPassword} />
      <Route path={'/recover/:token'} component={RecoverToken} />
      <Route path={'/reset-document'} component={RecoverDocument} />
      <Route path={'/token'} component={RecoverGetToken} />
      <Route path={'/sign-up'} component={SignUp} />
      <Route
        path={'/mobile/contract/:id/:number?'}
        component={ViewMobileContract}
      />
      <Route path={'/contract/:id/:number?'} component={ViewContract} />
      <Route
        path={'/proof/:platform/:movementKey/:idBallanceOrOrder?'}
        component={ViewProof}
      />
      {payment.map((route, index) => (
        <ProtectedRouter
          key={index}
          path={route.path}
          permissions={route.permissions}
          children={
            <TabMenu>
              <Header />
              <div style={{ marginTop: '0rem' }}>
                <route.main />
              </div>
            </TabMenu>
          }
        />
      ))}
      <Route path={'*'} component={Login} />
    </Switch>
  );
};

export default Routes;
