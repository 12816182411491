import { Image, Text, View } from '@react-pdf/renderer';

import iconCard from '../../../../Assets/iconCard.png';
import iconCheck from '../../../../Assets/iconCheck.png';
import iconCollected from '../../../../Assets/iconeCollected.png';
import oxpay from '../../../../Assets/oxpay.png';
import abc_cargas from '../../../../Assets/whiteLabel/abc_cargas.png';
import faster_log from '../../../../Assets/whiteLabel/faster_log.png';

import { formatMoney } from '../../../../utils/formatMoney';
import { proofHandleTitle } from '../../components/Utils';
import { themes } from '../../Themes';
import { getStyleProof } from '../styles';

export function ProofTemplateHeader({ platform, proof, movement }) {
  const theme = themes[platform] || themes.default;
  const styles = getStyleProof(theme);

  return (
    <View style={styles.header}>
      {!!platform && platform === 'abc_cargas' ? (
        <Image src={abc_cargas} style={styles.iconOx} />
      ) : !!platform && platform === 'faster_log' ? (
        <Image src={faster_log} style={styles.iconOx} />
      ) : (
        <Image src={oxpay} style={styles.iconOx} />
      )}
      {movement && movement.method === '13' ? (
        <Image
          src={iconCard}
          style={
            movement.status === '06'
              ? styles.iconCardSuccess
              : styles.iconCardFailed
          }
        />
      ) : movement &&
        movement?.method === '07' &&
        proof?.details.filter((v) => v.field === 'Data de Antecipação')
          .length ? (
        <Image src={iconCollected} style={styles.iconSuccess} />
      ) : (
        <Image src={iconCheck} style={styles.iconSuccess} />
      )}
      {movement && movement.method === '12' ? (
        <Text style={styles.subTitle}>Sucesso!</Text>
      ) : movement &&
        movement.method !== '13' &&
        !proof?.details.filter((v) => v.field === 'Data de Antecipação')
          .length ? (
        <Text style={styles.subTitle}>Pagamento realizado</Text>
      ) : (
        ''
      )}
      {movement && movement.method === '13' ? (
        <View style={styles.headerTitle}>
          <Text style={styles.title}>{formatMoney(movement.amount)}</Text>
          <Text style={styles.subTitleBlue}>{movement.name}</Text>
          <View style={styles.separator} />
        </View>
      ) : movement &&
        movement?.method === '07' &&
        proof?.details.filter((v) => v.field === 'Data de Antecipação')
          .length ? (
        <View style={styles.headerTitle}>
          <Text style={styles.title}>
            {formatMoney(movement?.billed_amount || movement?.amount)}
          </Text>
          <Text style={styles.subTitleBlue}>Desconto de Antecipação</Text>
        </View>
      ) : (
        <View style={styles.headerTitle}>
          {movement && movement.method !== '12' ? (
            <Text style={styles.title}>{movement && 'Comprovante de'}</Text>
          ) : (
            ''
          )}
          <Text
            style={
              proofHandleTitle(movement.method).length < 33
                ? styles.title
                : styles.titleBig
            }
          >
            {movement && proofHandleTitle(movement.method)}
          </Text>
        </View>
      )}
    </View>
  );
}
