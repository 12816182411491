/**
 * Formata um número de telefone brasileiro.
 *
 * Esta função remove todos os caracteres não numéricos do número de telefone fornecido
 * e aplica a formatação adequada, considerando números com ou sem o "9" adicional
 * dos celulares e também tratando o código do país (55).
 *
 * @param {string} phoneNumber O número de telefone a ser formatado. Pode conter
 * caracteres não numéricos.
 * @returns {string} O número de telefone formatado no formato "(XX) XXXX-XXXX"
 * ou "(XX) XXXXX-XXXX" (com o 9), ou o próprio número sem formatação caso
 * não se encaixe nos padrões ou seja vazio. Retorna string vazia caso o input seja nulo ou undefined.
 *
 * @example
 * formatPhoneNumber("11987654321") // Retorna "(11) 98765-4321"
 * formatPhoneNumber("1187654321")  // Retorna "(11) 8765-4321"
 * formatPhoneNumber("5511987654321") // Retorna "(11) 98765-4321" (com código do país)
 * formatPhoneNumber("987654321") // Retorna "(9) 876-5432"
 * formatPhoneNumber("abc1234567") // Retorna "123-4567"
 * formatPhoneNumber("") // Retorna ""
 * formatPhoneNumber(null) // Retorna ""
 * formatPhoneNumber(undefined) // Retorna ""
 */
export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return ''; // Trata entradas nulas ou indefinidas

  phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove caracteres não numéricos

  let countryCodeLength = 0;

  if (phoneNumber.startsWith('55')) {
    countryCodeLength = 2; // Define o comprimento do código do país para 2 (55)
  }

  phoneNumber = phoneNumber.slice(countryCodeLength); // Remove o código do país, se presente

  if (phoneNumber.length === 11) {
    // Formata números com 11 dígitos (com o 9)
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (phoneNumber.length === 10) {
    // Formata números com 10 dígitos (sem o 9)
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else if (phoneNumber.length === 9) {
    // Formata números com 9 dígitos (sem DDD)

    phoneNumber = phoneNumber.replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
  }

  return phoneNumber; // Retorna o número formatado ou original
}
