import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'RobotoBold',
  src: '/roboto-bold.ttf',
});

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 11,
    fontWeight: 'normal',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    marginVertical: 20,
    padding: 22,
  },
  report: {
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  contentPage: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  content: {
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  iconOx: {
    width: 110,
    minHeight: 26,
    alignSelf: 'center',
    marginBottom: 20,
  },
  info: {
    minWidth: '70%',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  infoCompany: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 8,
    gap: 6,
    borderBottomWidth: 1,
    borderBottomColor: '#001A5D',
  },
  headerTitle: {
    marginTop: 16,
    marginBottom: 38,
    flexDirection: 'column',
    gap: 5,
  },
  dashboard: {},
  transactionsList: {
    flexDirection: 'column',
    gap: 16,
  },
  line: {
    flexDirection: 'row',
    minWidth: '100%',
    justifyContent: 'space-between',
  },
  col1: {
    flexWrap: 'wrap',
    width: '30%',
  },
  col2: {
    flexBasis: '25%',
    flexShrink: 2,
    justifyContent: 'flex-start',
  },
  colEnd: {
    justifyContent: 'flex-end',
    flexBasis: '25%',
    flexShrink: 1,
    textAlign: 'right',
  },
  colName: {
    flexBasis: '25%',
    flexShrink: 4,
    minWidth: '25%',
    textAlign: 'center',
    flex: 1,
  },
  inText: {
    width: '15%',
    color: '#001A5D',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  outText: {
    width: '15%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  in: {
    width: '15%',
    color: '#001A5D',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  out: {
    width: '15%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'RobotoBold',
    fontSize: 16,
    color: '#001A5D',
    marginVertical: 8,
  },
  bigTitle: {
    textAlign: 'center',
    fontFamily: 'RobotoBold',
    fontSize: 20,
    color: '#001A5D',
    marginTop: 12,
  },
  subTitle: {
    textAlign: 'center',
    fontFamily: 'RobotoBold',
    fontSize: 14,
    color: '#001A5D',
    marginVertical: 8,
  },
  text: {
    color: '#333',
  },
  textBlue: {
    color: '#001A5D',
  },
  textJustify: {
    marginBottom: 5,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  textCenter: {
    textAlign: 'center',
  },
  rowContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    flexDirection: 'column',
    textAlign: 'justify',
    gap: 6,
  },
  bold: {
    fontFamily: 'RobotoBold',
    fontSize: 11,
    fontWeight: 'bold',
    color: '#242424',
  },
  boldBlue: {
    color: '#001A5D',
    fontFamily: 'RobotoBold',
    fontSize: 11,
    fontWeight: 'bold',
  },
  section: {
    marginVertical: 16,
  },
  sectionTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 22,
  },
  separator: {
    borderTopWidth: 1,
    borderColor: '#ccc',
    borderStyle: 'solid',
    marginVertical: 14,
  },
  footer: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 22,
  },
});
