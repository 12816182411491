import { LoadingBall } from '../../../../components/UI/LoadingBall';
import CardList from './Components/CardList';
import styles from './styles.module.scss';
const InvoiceTable = (props) => {
  return (
    <div className={styles.container}>
      <table className={styles.table_container}>
        <thead>
          <tr>
            <th className={styles.input_select_blue}>
              <input
                type="checkbox"
                checked={props?.firstClick === false}
                onChange={props?.selectAll}
              />
            </th>
            <th>Chave</th>
            <th>Número</th>
            <th>Nome</th>
            <th>CNPJ</th>
            <th>Valor</th>
            <th>CNAE</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.invoices?.length > 0 ? (
            props.invoices.map((invoice) => (
              <CardList
                key={invoice?.key}
                invoice={invoice}
                handlePushRouter={props.handlePushRouter}
                toggleSelect={props.toggleSelect}
                listSelect={props.listSelect}
              />
            ))
          ) : (
            <>
              {props.isFetching ? (
                <tr>
                  <td colSpan={9} className={styles['no-users']}>
                    <LoadingBall />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={10} className={styles['no-users']}>
                    Nenhuma nota encontrada !
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
