import { useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useHover } from 'usehooks-ts';
import { version } from '../../config';
import { getCookieCompanyId } from '../../Helpers/cookies';
import useSurveys from '../../hooks/useSurveys';
import { useUser } from '../../hooks/useUser';
import { AppTutorialModal } from '../AppTutorialModal';
import {
  IconAddBalance,
  IconAnticipation,
  IconBalancesAccounts,
  IconBankSlip,
  IconCIOT,
  IconCollaborator,
  IconExtract,
  IconInfoCircle,
  IconInvoice,
  IconLimit,
  IconLockPasswordLine,
  IconLogo,
  IconLogoSmall,
  IconLogout,
  IconPayment,
  IconPreference,
  IconQuestion,
  IconRulesProfile,
  IconUsers,
} from './Icon';
import styles from './styles.module.scss';

/**
 * Verifica se um caminho específico existe em um array de caminhos.
 *
 * @param {Array} arrayOfPaths - Um array de caminhos a serem verificados.
 * @param {String} pathToCheck - O caminho que você deseja verificar se existe no array.
 * @returns {boolean} - Retorna `true` se o `pathToCheck` for encontrado no `arrayOfPaths`, `false` caso contrário.
 *
 * @example
 * const arrayOfPaths = ['/menu/collaborator', '/menu/contas'];
 * const pathToCheck = '/menu/collaborator/938c24f8-1bdd-432b-a2e5-63cdc0454e09';
 *
 * const resultado = checkPath(arrayOfPaths, pathToCheck);
 */

function checkPath(arrayOfPaths, pathToCheck) {
  return arrayOfPaths.some((item) => pathToCheck.startsWith(item));
}

function TabMenu({ children }) {
  const { triggerSurvey, surveyCSAT, surveyForm, surveyNPC } = useSurveys();
  const hoverRef = useRef(null);
  const heverContent = useRef(null);
  const { pathname } = useLocation();
  const { id } = useParams();
  const not_select = 'var(--gray-300)';
  const _select = 'var(--primary)';
  const _select_menu = 'var(--gray-300)';

  const active_drop_menu = [
    '/menu/profile',
    '/menu/password',
    '/menu/added_balance',
    '/menu/customers',
    '/menu/info-profile',
    '/menu/limits',
  ].includes(pathname);
  const {
    user,
    expend,
    setExpend,
    isLinkClicked,
    setIsLinkClicked,
    setDropMenu,
    userLogout,
  } = useUser();
  const handleLinkClick = () => {
    setIsLinkClicked(true);
  };
  const isHover = useHover(hoverRef);
  const isHover_content = useHover(heverContent);
  useEffect(() => {
    if (isLinkClicked) {
      return;
    }
    if (isHover) {
      setExpend(true);
    }
    if (!isHover) {
      setExpend(false);
    }
    // eslint-disable-next-line
  }, [isHover, isLinkClicked]);
  const company = getCookieCompanyId();

  async function handleBeforeLogout() {
    try {
      const res = await triggerSurvey('2', user);

      if (
        surveyCSAT.open === false &&
        surveyForm.open === false &&
        surveyNPC.open === false &&
        !!res === false
      ) {
        userLogout();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={styles.container}>
      <AppTutorialModal />
      <div
        className={
          isLinkClicked
            ? styles.tabMenu
            : expend
            ? `${styles.tabMenu} ${styles.tabMenu_animation}`
            : styles.tabMenu_expend
        }
        style={{
          zIndex: 9999,
        }}
        ref={hoverRef}
      >
        <div className={styles.list}>
          <div className={expend ? styles.logo : styles.logo_expend}>
            {expend ? <IconLogo /> : <IconLogoSmall size={'3rem'} />}
          </div>
          <div className={styles.wrapper__selection}>
            {/* transferencia */}
            {[16].every((item) => user?.permission.includes(item)) && (
              <>
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDropMenu(false)}
                  className={
                    ['/menu/transfer', '/menu/order', '/menu/analyze'].includes(
                      pathname.slice(id),
                    ) || ['/menu/order'].includes(pathname.split(`/${id}`)[0])
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <Link onClick={handleLinkClick} id="link" to="/menu/transfer">
                    <span className={styles.wrapper_icon}>
                      <IconPayment
                        color={
                          [
                            '/menu/transfer',
                            '/menu/order',
                            '/menu/analyze',
                          ].includes(pathname.slice(id)) ||
                          ['/menu/order'].includes(pathname.split(`/${id}`)[0])
                            ? _select
                            : not_select
                        }
                        className={styles.ico}
                        size="2rem"
                      />
                    </span>
                    {expend && (
                      <span className={!isLinkClicked && styles.text_animeted}>
                        Pagamentos
                      </span>
                    )}
                  </Link>
                </li>
              </>
            )}
            {/* boleto */}
            {[16].every((item) => user?.permission.includes(item)) && (
              <>
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDropMenu(false)}
                  className={
                    ['/menu/bank-slip'].includes(pathname.slice(id)) ||
                    ['/menu/bank-slip'].includes(pathname.split(`/${id}`)[0])
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <Link
                    onClick={handleLinkClick}
                    id="link"
                    to="/menu/bank-slip"
                  >
                    <span className={styles.wrapper_icon}>
                      <IconBankSlip
                        color={
                          ['/menu/bank-slip'].includes(pathname.slice(id)) ||
                          ['/menu/bank-slip'].includes(
                            pathname.split(`/${id}`)[0],
                          )
                            ? _select
                            : not_select
                        }
                        className={styles.ico}
                        size="2rem"
                      />
                    </span>
                    {expend && (
                      <span className={!isLinkClicked && styles.text_animeted}>
                        Boletos
                      </span>
                    )}
                  </Link>
                </li>
              </>
            )}
            {/** Notas */}
            {user?.company?.receipt_enabled &&
              [69].every((item) => user?.permission.includes(item)) && (
                <>
                  <li
                    style={{ cursor: 'pointer' }}
                    onClick={() => setDropMenu(false)}
                    className={
                      ['/menu/invoice'].includes(pathname.slice(id)) ||
                      ['/menu/invoice'].includes(pathname.split(`/${id}`)[0])
                        ? `${styles.borderSelect} ${styles.tab}`
                        : styles.tab
                    }
                  >
                    <Link
                      onClick={handleLinkClick}
                      id="link"
                      to="/menu/invoice"
                    >
                      <span className={styles.wrapper_icon}>
                        <IconInvoice
                          color={
                            ['/menu/invoice'].includes(pathname.slice(id)) ||
                            ['/menu/invoice'].includes(
                              pathname.split(`/${id}`)[0],
                            )
                              ? _select
                              : not_select
                          }
                          className={styles.ico}
                          size="1.8rem"
                        />
                      </span>
                      {expend && (
                        <span
                          className={!isLinkClicked && styles.text_animeted}
                        >
                          Notas
                        </span>
                      )}
                    </Link>
                  </li>
                </>
              )}
            {/* //consignment */}
            {[16].every((item) => user?.permission.includes(item)) && (
              <>
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDropMenu(false)}
                  className={
                    ['/menu/consignment', '/menu/consignment/order'].includes(
                      pathname.slice(id),
                    ) ||
                    ['/menu/consignment/order'].includes(
                      pathname.split(`/${id}`)[0],
                    )
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <Link
                    onClick={handleLinkClick}
                    id="link"
                    to="/menu/consignment"
                  >
                    <span className={styles.wrapper_icon}>
                      <IconAnticipation
                        color={
                          [
                            '/menu/consignment',
                            '/menu/consignment/order',
                          ].includes(pathname.slice(id)) ||
                          ['/menu/consignment/order'].includes(
                            pathname.split(`/${id}`)[0],
                          )
                            ? _select
                            : not_select
                        }
                        className={styles.ico}
                        size="2rem"
                      />
                    </span>
                    {expend && (
                      <span className={!isLinkClicked && styles.text_animeted}>
                        Antecipações
                      </span>
                    )}
                  </Link>
                </li>
              </>
            )}
            {/* contas */}
            {[44, 46].every((item) => user?.permission.includes(item)) && (
              <>
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDropMenu(false)}
                  className={
                    checkPath(
                      [
                        '/menu/contas',
                        '/menu/account-customer',
                        '/menu/group-customer',
                        '/menu/group-customer',
                      ],
                      pathname,
                    )
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <Link onClick={handleLinkClick} id="link" to={'/menu/contas'}>
                    <span className={styles.wrapper_icon}>
                      <IconCollaborator
                        color={
                          checkPath(
                            [
                              '/menu/contas',
                              '/menu/account-customer',
                              '/menu/group-customer',
                              '/menu/group-customer',
                            ],
                            pathname,
                          )
                            ? _select
                            : not_select
                        }
                        className={styles.ico}
                        size="2rem"
                      />
                    </span>
                    {expend && (
                      <span className={!isLinkClicked && styles.text_animeted}>
                        Cadastros
                      </span>
                    )}
                  </Link>
                </li>
              </>
            )}
            {/* Carteira */}
            {[].every((item) => user?.permission.includes(item)) && (
              <>
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDropMenu(false)}
                  className={
                    [
                      '/menu/balances',
                      '/menu/account',
                      '/menu/company',
                    ].includes(pathname)
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <Link
                    onClick={handleLinkClick}
                    id="link"
                    to={'/menu/balances'}
                  >
                    <span className={styles.wrapper_icon}>
                      <IconBalancesAccounts
                        color={
                          ['/menu/balances', '/menu/account'].includes(
                            pathname,
                          ) || ['/menu/company'].includes(pathname)
                            ? _select
                            : not_select
                        }
                        className={styles.ico}
                        size="2rem"
                      />
                    </span>
                    {expend && (
                      <span className={!isLinkClicked && styles.text_animeted}>
                        Contas
                      </span>
                    )}
                  </Link>
                </li>
              </>
            )}
            {/* Extrato */}
            {[5].every((item) => user?.permission.includes(item)) && (
              <li
                className={
                  ['/menu/extract'].includes(pathname.split(`/${id}`)[0])
                    ? `${styles.borderSelect} ${styles.tab}`
                    : styles.tab
                }
                onClick={() => setDropMenu(false)}
              >
                <Link
                  onClick={handleLinkClick}
                  id="link"
                  to={`/menu/extract/${company}`}
                >
                  <span className={styles.wrapper_icon}>
                    <IconExtract
                      color={
                        ['/menu/extract'].includes(pathname.split(`/${id}`)[0])
                          ? _select
                          : not_select
                      }
                      className={styles.ico}
                      size="2rem"
                    />
                  </span>
                  {expend && (
                    <span className={!isLinkClicked && styles.text_animeted}>
                      Extrato
                    </span>
                  )}
                </Link>
              </li>
            )}
            {/* ciot */}
            {[].every((item) => user?.permission.includes(item)) && (
              <>
                <li
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDropMenu(false)}
                  className={
                    ['/menu/ciot'].includes(pathname)
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <a
                    id="ciot"
                    href="https://www.webrouter.com.br/oxpay/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className={styles.wrapper_icon}>
                      <IconCIOT
                        color={
                          ['/menu/ciot'].includes(pathname)
                            ? _select
                            : not_select
                        }
                        className={styles.ico}
                        size="2rem"
                      />
                    </span>
                    {expend && (
                      <span className={!isLinkClicked && styles.text_animeted}>
                        CIOT + VPO
                      </span>
                    )}
                  </a>
                </li>
              </>
            )}
            {/* Informação contas  */}
            {[].every((item) => user?.permission.includes(item)) && (
              <div ref={heverContent} className={styles.drop_menu_expand}>
                <li
                  style={{ cursor: 'pointer' }}
                  className={
                    [
                      '/menu/profile',
                      '/menu/password',
                      '/menu/added_balance',
                      '/menu/customers',
                      '/menu/info-profile',
                      '/menu/limits',
                    ].includes(pathname)
                      ? `${styles.borderSelect} ${styles.tab}`
                      : styles.tab
                  }
                >
                  <span className={styles.wrapper_icon}>
                    <IconPreference
                      color={
                        [
                          '/menu/profile',
                          '/menu/password',
                          '/menu/added_balance',
                          '/menu/customers',
                          '/menu/info-profile',
                          '/menu/limits',
                        ].includes(pathname)
                          ? _select
                          : not_select
                      }
                      className={styles.ico}
                      size="1.8rem"
                    />
                  </span>
                  {expend && (
                    <span className={!isLinkClicked && styles.text_animeted}>
                      Preferências
                    </span>
                  )}
                </li>
                <ul
                  className={
                    active_drop_menu ? styles.drop_animation : styles.drop_menu
                  }
                >
                  {[].every((item) => user?.permission.includes(item)) && (
                    <li
                      className={
                        ['/menu/customers'].includes(pathname)
                          ? `${styles.borderSelect_menu} ${styles.tab_menu}`
                          : styles.tab_menu
                      }
                    >
                      <Link
                        onClick={handleLinkClick}
                        id="link"
                        to="/menu/customers"
                      >
                        <IconUsers color={_select_menu} size="1.5rem" />
                        {expend && (
                          <span
                            className={!isLinkClicked && styles.text_animeted}
                          >
                            Usuários
                          </span>
                        )}
                      </Link>
                    </li>
                  )}
                  {/* Limits */}
                  {[58].every((item) => user?.permission.includes(item)) && (
                    <li
                      className={
                        ['/menu/limits'].includes(pathname)
                          ? `${styles.borderSelect_menu} ${styles.tab_menu}`
                          : styles.tab_menu
                      }
                    >
                      <Link
                        onClick={handleLinkClick}
                        id="link"
                        to="/menu/limits"
                      >
                        <IconLimit color={_select_menu} size="1.5rem" />
                        {expend && (
                          <span
                            className={!isLinkClicked && styles.text_animeted}
                          >
                            Limites
                          </span>
                        )}
                      </Link>
                    </li>
                  )}
                  <li
                    className={
                      pathname.includes('/menu/profile')
                        ? `${styles.borderSelect_menu} ${styles.tab_menu}`
                        : styles.tab_menu
                    }
                  >
                    <Link
                      onClick={handleLinkClick}
                      id="link"
                      to="/menu/profile"
                    >
                      <IconInfoCircle color={_select_menu} size="1.5rem" />

                      {expend && (
                        <span
                          className={!isLinkClicked && styles.text_animeted}
                        >
                          Informações
                        </span>
                      )}
                    </Link>
                  </li>
                  <li
                    className={
                      pathname.includes('/menu/info-profile')
                        ? `${styles.borderSelect_menu} ${styles.tab_menu}`
                        : styles.tab_menu
                    }
                  >
                    <Link
                      onClick={handleLinkClick}
                      id="link"
                      to="/menu/info-profile"
                    >
                      <IconRulesProfile color={_select_menu} size="1.5rem" />

                      {expend && (
                        <span
                          className={!isLinkClicked && styles.text_animeted}
                        >
                          Regras de Perfis
                        </span>
                      )}
                    </Link>
                  </li>
                  <li
                    className={
                      pathname.includes('/menu/password')
                        ? `${styles.borderSelect_menu} ${styles.tab_menu}`
                        : styles.tab_menu
                    }
                  >
                    <Link
                      onClick={handleLinkClick}
                      id="link"
                      to="/menu/password"
                    >
                      <IconLockPasswordLine
                        color={_select_menu}
                        size="1.5rem"
                      />

                      {expend && (
                        <span
                          className={!isLinkClicked && styles.text_animeted}
                        >
                          Mudar senha
                        </span>
                      )}
                    </Link>
                  </li>
                  <li
                    className={
                      pathname.includes('/menu/added_balance')
                        ? `${styles.borderSelect_menu} ${styles.tab_menu}`
                        : styles.tab_menu
                    }
                  >
                    <Link
                      onClick={handleLinkClick}
                      id="link"
                      to="/menu/added_balance"
                    >
                      <IconAddBalance color={_select_menu} size="1.5rem" />

                      {expend && (
                        <span
                          className={!isLinkClicked && styles.text_animeted}
                        >
                          Adicionar Saldo
                        </span>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {expend && !isHover_content && !active_drop_menu ? (
              <li
                className={
                  isLinkClicked
                    ? styles.card_faq
                    : `${styles.card_faq} ${styles.card_faq_animation}`
                }
              >
                <div className={styles.card_faq_icon}>
                  <IconQuestion color={'var(--primary)'} size="1.5rem" />
                </div>
                <div className={styles.card_faq_header}>
                  <h4>Precisa de ajuda?</h4>
                  <span>Fale com nosso time.</span>
                </div>
                <a
                  href={`https://wa.me/551152950976?text=Ol%C3%A1%20sou%20${user?.customer?.social_name}%2C%20da%20${user?.company?.social_name}%20-%20${user?.company?.document}%20ciclano%2C%20preciso%20de%20ajuda%20na%20plataforma%20da%20conta%20Ox%20Pay!`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Entrar em contato
                </a>
              </li>
            ) : (
              <li
                className={`${styles.card_faq_close} ${styles.card_faq_animation_close}`}
              >
                <div className={styles.card_faq_header}>
                  <h4>Precisa de ajuda?</h4>
                  <span>Fale com nosso time.</span>
                </div>
                <a
                  href={`https://wa.me/551152950976?text=Ol%C3%A1%20sou%20${user?.customer?.social_name}%2C%20da%20${user?.company?.social_name}%20-%20${user?.company?.document}%20ciclano%2C%20preciso%20de%20ajuda%20na%20plataforma%20da%20conta%20Ox%20Pay!`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Entrar em contato
                </a>
              </li>
            )}
          </div>
        </div>
        <div className={styles.card_faq_footer}>
          <li
            onClick={handleBeforeLogout}
            style={{ cursor: 'pointer' }}
            className={styles.tab}
          >
            <span className={styles.wrapper_icon}>
              <IconLogout
                color={not_select}
                className={styles.ico}
                size={'1.8rem'}
              />
            </span>
            {expend && (
              <span className={!isLinkClicked && styles.text_animeted}>
                Sair
              </span>
            )}
          </li>
          {expend && <span className={styles.text_version}>v{version}</span>}
        </div>
      </div>

      <div
        onClick={() => setIsLinkClicked(false)}
        className={
          isLinkClicked
            ? styles.containerChildren
            : expend
            ? `${styles.containerChildren} ${styles.containerChildren_animation}`
            : `${styles.containerChildren_} ${styles.containerChildren_animation_}`
        }
      >
        {children}
      </div>
    </div>
  );
}

export default TabMenu;
