import { format, parseISO, subHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatMoney } from '../../../utils/formatMoney';

export const anticipationSchema = {
  details: [
    {
      object: 'order',
      field: 'total_amount',
      translate: 'Valor Antecipado',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: 'fee_amount',
      translate: 'Taxa',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: 'amount',
      translate: 'Total Recebido',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data de Antecipação',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Horário',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
    {
      object: 'consignable_limits',
      field: 'valid_until',
      translate: 'Data de Desconto',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
      handle: (v) => v,
    },
  ],
};
