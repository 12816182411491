import React, { useState } from 'react';
import styles from './styles.module.scss';
import SurveyList from './Components/SurveyList';
import useSurveys from '../../hooks/useSurveys';
import { IconClose } from '../GroupOnboarding/Icons';
import { v4 as uid } from 'uuid';
import { actionsSurveys, frequencySurveys, typesSurveys } from './data';
import { Loader } from 'rsuite';
const CreateSurveyForm = () => {
  const { addSurvey, loading, surveys } = useSurveys();
  const listSurvey =
    surveys.length > 0
      ? surveys
          .filter((item) => item.isActive === true)
          .map((item) => item.action)
      : [];
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [type, setType] = useState('');
  const [action, setAction] = useState('');
  const [frequency, setFrequency] = useState('');

  const [isActive, setIsActive] = useState(true);
  const [formFields, setFormFields] = useState([]);
  const [inputCount, setInputCount] = useState(0);

  const addFormField = () => {
    if (inputCount < 6) {
      setFormFields([...formFields, { name: '', type: '', id: uid() }]);
      setInputCount(inputCount + 1);
    }
  };

  const removeFormField = (id) => {
    if (inputCount <= 6) {
      const newFields = formFields.filter((item) => item.id !== id);
      setFormFields(newFields);
      setInputCount(inputCount - 1);
    }
  };

  const updateFormField = (index, key, value) => {
    const updatedFields = [...formFields];
    updatedFields[index][key] = value;
    setFormFields(updatedFields);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const payload = {
      name,
      type,
      title,
      description,
      action,
      isActive,
      frequency,
      created_at: new Date(),
    };

    if (type === '1') {
      payload.fields = formFields;
    } else if (type === '2') {
      payload.options = ['Muito Ruim', 'Ruim', 'Regular', 'Bom', 'Ótimo'];
    } else if (type === '3') {
      payload.range = [0, 10];
    }

    try {
      await addSurvey(payload);
      resetForm();
      alert('Pesquisa salva com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar pesquisa:', error);
    }
  };

  const resetForm = () => {
    setName('');
    setType('');
    setAction('');
    setDescription('');
    setTitle('');
    setIsActive(true);
    setFormFields([]);
    setInputCount(0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header_style_}>
        <div className={styles.title_style}>Criar Pesquisa</div>
      </div>
      <form className={styles.form} onSubmit={handleSave}>
        <div className={styles.form_box}>
          <div className={styles.wrapper}>
            <label>Nome:</label>
            <input
              type="text"
              value={name}
              disabled={loading?.surveys}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className={styles.wrapper}>
            <label>Titulo:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={loading?.surveys}
              required
            />
          </div>

          <div className={styles.select_type}>
            <div className={styles.select}>
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                disabled={loading?.surveys}
                required
              >
                <option value="">Pesquisa</option>
                {typesSurveys.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.description}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.select_type}>
            <div className={styles.select}>
              <select
                value={action}
                onChange={(e) => setAction(e.target.value)}
                disabled={loading?.surveys}
                required
              >
                <option value="">Ação</option>
                {actionsSurveys
                  .filter((objeto) => !listSurvey.includes(objeto.id))
                  .map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className={styles.select_type}>
            <div className={styles.select}>
              <select
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                disabled={loading?.surveys}
                required
              >
                <option value="">Frequência</option>
                {frequencySurveys
                  .filter((objeto) => !listSurvey.includes(objeto.id))
                  .map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <label className={styles.input_select}>
            <input
              type="checkbox"
              checked={isActive}
              disabled={loading?.surveys}
              onChange={() => setIsActive(!isActive)}
              required
            />
          </label>
        </div>
        <div>
          <div className={styles.wrapper}>
            <label>Descrição:</label>
            <textarea
              type="text"
              value={description}
              disabled={loading?.surveys}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
        </div>
        <div className={styles.box}>
          {type === '1' && (
            <>
              <h4 className={styles.title}>Campos do Formulário (Máximo 6)*</h4>
              <div className={styles.form_edit}>
                {formFields.map((field, index) => (
                  <div className={styles.edit_form} key={index}>
                    <div className={styles.wrapper}>
                      <label>Nome:</label>
                      <input
                        type="text"
                        placeholder="Nome"
                        value={field.name}
                        required
                        disabled={loading?.surveys}
                        onChange={(e) =>
                          updateFormField(index, 'name', e.target.value)
                        }
                      />
                    </div>
                    <div className={styles.select_type}>
                      <div className={styles.select}>
                        <select
                          value={field.type}
                          onChange={(e) =>
                            updateFormField(index, 'type', e.target.value)
                          }
                          required
                        >
                          <option value="">Tipo</option>
                          <option value="text">Texto</option>
                          <option value="number">Número</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{ margin: 'auto 0', background: 'transparent' }}
                      onClick={() => {
                        removeFormField(field.id);
                      }}
                      type="button"
                      disabled={loading?.surveys}
                    >
                      <IconClose size="1.5rem" />
                    </div>
                  </div>
                ))}
                <button
                  className={styles.button}
                  type="button"
                  onClick={addFormField}
                  disabled={inputCount >= 6}
                >
                  Adicionar
                </button>
              </div>
            </>
          )}
        </div>
        <div className={styles.wrapper_button}>
          <button
            className={styles.button}
            disabled={loading?.surveys}
            type="submit"
          >
            {loading?.surveys ? <Loader /> : 'Criar'}
          </button>
        </div>
      </form>
      <SurveyList />
    </div>
  );
};

export default CreateSurveyForm;
