import { Document, Page, View } from '@react-pdf/renderer';
import { ExtractTemplateFooter } from './Footer';
import { ExtractTemplateHeader } from './Header';

import { ExtractTemplateContent } from './Content';
import { ExtractTemplateDashboard } from './Dashboard';
import { styles } from './styles';

export default function ExtractReportTemplate({ list, user, period, report }) {
  return (
    <Document>
      <Page size={'A4'} style={styles.page}>
        <View style={styles.contentPage}>
          <ExtractTemplateHeader user={user} />
          <View style={styles.report}>
            <ExtractTemplateDashboard period={period} report={report} />
            <ExtractTemplateContent data={list} />
          </View>
        </View>
        <ExtractTemplateFooter />
      </Page>
    </Document>
  );
}
