import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ExportButton } from './ExportButton';
import ExtractReportTemplate from './ExtractReportTemplate';
import { formatGroup } from './utils/formatGroup';

import { formatDateNoSubTime } from '../../../../utils/formatDate';
import { formatDashboard } from './utils/formatDashboard';

export const ExportPDFExtract = ({ list, title, user, period }) => {
  async function HandleDownloadPDF() {
    const data = formatGroup(list);
    const report = formatDashboard(data);

    const doc = (
      <ExtractReportTemplate
        list={data}
        user={user}
        period={period}
        report={report}
      />
    );

    try {
      const blob = await pdf(doc).toBlob();
      const name =
        user?.fantasy_name ||
        user?.company?.social_name ||
        user?.company?.fantasy_name ||
        'do';

      saveAs(
        blob,
        `Extrato_${name}_${formatDateNoSubTime(
          period.dateInitial,
        )}_a_${formatDateNoSubTime(period.dateFinally)}.pdf`,
      );
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error);
    }
  }

  return <ExportButton title={title} onClick={HandleDownloadPDF} />;
};
