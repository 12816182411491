import { formatDate } from './formatDate';

export const formatCompaniesData = (array) => {
  return array.map((company) => ({
    default_pix_key: company?.default_pix_key ?? false,
    consignation_enabled: company?.consignation_enabled ?? false,
    receipt_enabled: company?.receipt_enabled ?? false,
    accountable: company?.accountable ? company.accountable.toUpperCase() : '',
    closing_day: company?.closing_day,
    cnpj: company?.cnpj,
    cpf: company?.cpf,
    document: company?.document,
    company_id: company?.company_id,
    contact: company?.contact,
    email: company?.email,
    date_added: formatDate(company?.date_added, 'dd/MM/yyyy'),
    social_reason: company?.social_reason ?? company?.accountable,
    fantasy_name: company?.fantasy_name
      ? company.fantasy_name.toUpperCase()
      : company?.accountable.toUpperCase() ?? 'SEM NOME',
    month_installment_limit: company?.month_installment_limit,
    phone: company?.phone,
    type: company?.cnpj ? 'PJ' : 'PF',
    webhook_url: company?.webhook_url,
    transfer_fee: company?.transfer_fee,
    internal_transfer_fee: company?.internal_transfer_fee,
    accountable_cpf: company?.accountable_cpf,
    zipcode: company?.zipcode,
    street: company?.street,
    street_number: company?.street_number,
    street_complement: company?.street_complement,
    neighborhood: company?.neighborhood,
    city: company?.city,
    financially_blocked: company?.financially_blocked,
    balance: company?.balance,
    actual_closing_date: company?.actual_closing_date,
    last_closing_date: company?.last_closing_date,
    company_type: company.company_type,
    status_company: company?.financially_blocked
      ? 0
      : company.company_id === 4
      ? 2
      : company.company_type === 'Currier'
      ? 2
      : 3,
    social_name: company?.social_name
      ? company.social_name
      : company.fantasy_name
      ? company.fantasy_name
      : company?.accountable,
  }));
};
