import Hotjar from '@hotjar/browser';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { CustomProvider } from 'rsuite';
import pt_BR from 'rsuite/locales/pt_BR';
import Error from './components/Error';
import FlagStatus from './components/FlagStatus';
import Message from './components/Message';
import { NotificationSystem } from './components/Notification';
import ModalCSAT from './components/SurveysModal/ModalCSAT';
import ModalForm from './components/SurveysModal/ModalForm';
import ModalNPC from './components/SurveysModal/ModalNPC';
import { LoadingPageCompany } from './components/UI/LoadingPageCompany';
import CustomersProvider from './data/customersContext';
import PaymentProvider from './data/paymentContext';
import StoreProvider from './data/storeContext';
import Routes from './routes';
const ID = process.env.REACT_APP_HOT_JAR_ID;
const siteId = Number(ID);
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
const queryClient = new QueryClient();
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <CustomProvider locale={pt_BR}>
        <PaymentProvider>
          <StoreProvider>
            <CustomersProvider>
              <BrowserRouter>
                <ModalNPC />
                <ModalCSAT />
                <ModalForm />
                <LoadingPageCompany />
                <Message />
                <Error />
                <FlagStatus />
                <NotificationSystem />
                <Routes />
              </BrowserRouter>
            </CustomersProvider>
          </StoreProvider>
        </PaymentProvider>
      </CustomProvider>
    </QueryClientProvider>
  );
};

export default App;
