import { Text, View } from '@react-pdf/renderer';
import { themes } from '../../Themes';
import { getStyleProof } from '../styles';

export function ProofTemplateAnticipation({ platform, proof, movement }) {
  const theme = themes[platform] || themes.default;
  const styles = getStyleProof(theme);

  return movement &&
    movement?.method === '07' &&
    proof?.details.filter((v) => v.field === 'Data de Antecipação').length ? (
    <View style={styles.section}>
      <Text style={styles.sectionAnticipationTitle}>Sobre a antecipação</Text>
    </View>
  ) : (
    ''
  );
}
