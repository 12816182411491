import { Text, View } from '@react-pdf/renderer';
import { themes } from '../../Themes';
import { getStyleProof } from '../styles';
export function ProofTemplateDestiny({ platform, proof }) {
  const theme = themes[platform] || themes.default;
  const styles = getStyleProof(theme);

  return (
    <>
      {proof?.destiny ? (
        <View style={styles.section}>
          <View style={styles.separator} />
          <Text style={styles.sectionTitle}>Quem Recebeu</Text>
          {proof.destiny.map((item, i) => (
            <View key={i} style={styles.row}>
              <Text style={styles.label}>{item.field}</Text>
              <Text style={styles.value}>{item.value}</Text>
            </View>
          ))}
        </View>
      ) : (
        ''
      )}
    </>
  );
}
