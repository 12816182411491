import { Route, Redirect, useLocation } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotifications';
import { useUser } from '../../hooks/useUser';

function ProtectedRouter(props) {
  const { login, user } = useUser();
  const { pathname } = useLocation();
  const { addNotification } = useNotification();
  if (user && login === true) {
    if (
      [...props.permissions].every((item) => user?.permission.includes(item))
    ) {
      return <Route {...props} />;
    } else {
      if (
        [...props.permissions].every((item) => user?.permission.includes(item))
      ) {
        return <Route {...props} />;
      }
      addNotification(
        <>Infelizmente você não tem acesso a essa pagina!.</>,
        2,
        'Sem acesso!',
        true,
        22,
      );

      if ([16].every((item) => user?.permission.includes(item))) {
        return <Redirect to={'/menu/transfer'} />;
      }
      if ([16].every((item) => user?.permission.includes(item))) {
        return <Redirect to={'/menu/consignment'} />;
      }
      if ([44, 46].every((item) => user?.permission.includes(item))) {
        return <Redirect to={'/menu/contas'} />;
      } else {
        return <Redirect to={'/menu/balances'} />;
      }
    }
  } else {
    return <Redirect to={pathname} />;
  }
}

export default ProtectedRouter;
