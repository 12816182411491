function IconImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.875 13.125l4.3-4.3a1.875 1.875 0 012.65 0l4.3 4.3m-1.25-1.25l1.174-1.174a1.874 1.874 0 012.652 0l2.424 2.424m-15 3.125h13.75a1.25 1.25 0 001.25-1.25V5a1.25 1.25 0 00-1.25-1.25H3.125A1.25 1.25 0 001.875 5v10a1.25 1.25 0 001.25 1.25zm8.75-9.375h.007v.007h-.007v-.007zm.313 0a.312.312 0 11-.625 0 .312.312 0 01.624 0z"
      ></path>
    </svg>
  );
}

function IconDownloadImg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5625rem"
      height="1.5rem"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke="#242424"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.5 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118.5 19.5H7.25z"
      ></path>
    </svg>
  );
}

function IconRefuse({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '22'}
      height={size || '23'}
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        stroke="#E81010"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.512"
        d="m9.369 9.369 3.953 4.125m0-4.125L9.37 13.494m9.883-2.063a8.6 8.6 0 0 1-.602 3.158 8.3 8.3 0 0 1-1.714 2.676 7.9 7.9 0 0 1-2.565 1.788 7.6 7.6 0 0 1-3.026.628 7.6 7.6 0 0 1-3.025-.628 7.9 7.9 0 0 1-2.565-1.788 8.3 8.3 0 0 1-1.714-2.677 8.6 8.6 0 0 1-.602-3.157c0-2.188.833-4.286 2.316-5.833s3.494-2.417 5.59-2.417c2.097 0 4.108.87 5.591 2.417s2.316 3.645 2.316 5.833"
      ></path>
    </svg>
  );
}

function IconAprove() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.512"
        d="m9.096 12.119 2.062 2.062 3.438-4.812m5.5 2.062a8.25 8.25 0 1 1-16.5 0 8.25 8.25 0 0 1 16.5 0"
      ></path>
    </svg>
  );
}

function IconRefuseConfirm({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 89}
      height={height || 88}
      fill="none"
      viewBox="0 0 89 88"
    >
      <path
        fill="#C92519"
        d="M56.938 55.124a1.28 1.28 0 0 1 0 1.813 1.28 1.28 0 0 1-1.812 0L44.5 46.311 33.876 56.937a1.282 1.282 0 1 1-1.813-1.813L42.689 44.5 32.063 33.875a1.282 1.282 0 0 1 1.813-1.813L44.5 42.688l10.625-10.626a1.282 1.282 0 1 1 1.812 1.813L46.312 44.499z"
      ></path>
    </svg>
  );
}

export {
  IconAprove,
  IconDownloadImg,
  IconImage,
  IconRefuse,
  IconRefuseConfirm,
};
