export function separateFee(orders) {
  return orders.flatMap((order) => {
    if (order.fee_amount && order.fee_amount > 0) {
      const orderSemTaxa = {
        ...order,
        amount: order.amount,
        OFX: {
          ...order.OFX,
          TRNAMT: order.amount,
          TRNTYPE: order.OFX.TRNTYPE || '',
        },
      };

      const orderTaxa = {
        ...order,
        amount: order.fee_amount,
        title: {
          ...order.title,
          description: 'Taxa da transação',
        },
        OFX: {
          ...order.OFX,
          TRNAMT: order.fee_amount,
          TRNTYPE: 'FEE',
          MEMO: order?.title?.description.toLowerCase().includes('taxa')
            ? order?.title?.description
            : `Taxa referente à ${order?.title?.description}` ||
              'Cobrança de taxa',
        },
      };

      return [orderSemTaxa, orderTaxa];
    }
    return order;
  });
}
