import { saveAs } from 'file-saver';
import React from 'react';
import { useUser } from '../../../../hooks/useUser';
import { ExportButton } from './ExportButton';
import { FormatNameString } from './FormatNameString';
import { OFXGenerator } from './OFXGenerator';
import { separateFee } from './separateFee';

export const ExportOFXExtract = ({ list, title }) => {
  const { user } = useUser();
  const ofxGenerator = new OFXGenerator(user);
  const format_name = new FormatNameString();
  const handleExportClick = () => {
    const ofxData = ofxGenerator.generateOFX(separateFee(list) ?? list);
    const blobData = new Blob([ofxData], { type: 'application/x-ofx' });
    const name_file = format_name.formatNameDateTime();
    saveAs(blobData, name_file);
  };

  return <ExportButton title={title} onClick={handleExportClick} />;
};
