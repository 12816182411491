import React, { useEffect, useState } from 'react';
import { FaCheck, FaSpinner } from 'react-icons/fa'; // Ícone para diferentes estados
import { MdClose } from 'react-icons/md';
import styles from './styles.module.scss';

const CancelButton = ({ onConfirm, loading, textButton, stylesCustom }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5); // Define a contagem de segundos
  const [timer, setTimer] = useState(null);

  const handleClick = () => {
    if (isConfirming) {
      if (onConfirm) onConfirm();
      resetButton();
    } else {
      setIsConfirming(true);
      setSecondsLeft(5); // Reseta os segundos ao iniciar a confirmação
      const interval = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000); // Atualiza a cada 1 segundo
      setTimer(interval);

      setTimeout(() => {
        resetButton();
      }, 5000); // 5 segundos para confirmar
    }
  };

  const resetButton = () => {
    setIsConfirming(false);
    setSecondsLeft(5); // Reseta a contagem de segundos
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    if (secondsLeft === 0) {
      resetButton();
    }
    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  return (
    <button
      className={`${styles.button} ${isConfirming ? styles.confirming : ''} ${
        loading ? styles.loading : ''
      }`}
      style={stylesCustom && stylesCustom} // Aplica stylesCustom diretamente
      onClick={handleClick}
      disabled={loading} // Desabilita o botão enquanto está em loading
    >
      {loading ? (
        <FaSpinner className={styles.spinner} /> // Ícone de loading
      ) : isConfirming ? (
        <FaCheck />
      ) : (
        <MdClose size={'1.2rem'} />
      )}
      {loading
        ? textButton?.loading || 'Carregando...'
        : isConfirming
        ? `${textButton?.confirm || 'Confirmar'}` // Mostra a contagem regressiva
        : textButton?.initial || 'Aprovar'}
    </button>
  );
};

export default CancelButton;
