import axios from 'axios';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { StoreContext } from '../data/storeContext';
import { apiPost, getApiUrl, getHeaders } from '../services/api';
import { formatStatusInvoice } from '../utils/formatStatus';
import { useUser } from './useUser';
function formatReceiptsWithCompanies(receipts, companies) {
  return receipts.map((receipt) => {
    const sourceCompany = companies.find(
      (company) => company.company_id === receipt.source_company_id,
    );
    const targetCompany = companies.find(
      (company) => company.company_id === receipt.target_company_id,
    );

    return {
      ...receipt,
      status: formatStatusInvoice(receipt?.status),
      amount: receipt?.amount ?? 0,
      cnae: receipt?.service_code,
      source_company: sourceCompany ? sourceCompany : null,
      target_company: targetCompany ? targetCompany : null,
    };
  });
}
export function useInvoice() {
  const { setLoading, setError } = useContext(StoreContext);
  const { userLogout } = useUser();
  const queryClient = useQueryClient();

  async function fetchDataListInvoice(dateRanger) {
    setLoading(true);
    try {
      let hasMorePages = true;
      let allReceipts = [];
      let allCompanies = [];
      let page = 0;

      while (hasMorePages) {
        const { data } = await axios.get(
          getApiUrl(
            `/Receipt?createdSince=${dateRanger?.dateInitial}&createdUntil=${dateRanger?.dateFinally}&page=${page}`,
          ),
          {
            headers: getHeaders(),
          },
        );

        const { receipts, companies, has_more_pages } = data;

        allReceipts = [...allReceipts, ...receipts];
        allCompanies = companies; // Assuming companies don't change across pages
        hasMorePages = has_more_pages;
        page += 1;

        if (page > 20) {
          hasMorePages = false;
        }
      }

      // Relate receipts with their respective companies
      const formattedData = formatReceiptsWithCompanies(
        allReceipts,
        allCompanies,
      );

      return formattedData.reverse();
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Erro no servidor' });
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchReceiptKeyApprove(key) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/approve`);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function fetchReceiptKeyRefuse(key) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/refuse`);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function fetchReceiptKeyCancel(key) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/cancel`);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  return {
    fetchDataListInvoice,
    fetchReceiptKeyApprove,
    fetchReceiptKeyRefuse,
    fetchReceiptKeyCancel,
  };
}
