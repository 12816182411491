export const statusData = [
  {
    value: 1,
    orderStatusCode: '01',
    label: 'Aguardado aprovação',
    OrderStatusDescription: null,
  },
  {
    value: 2,
    orderStatusCode: '02',
    label: 'Erro',
    OrderStatusDescription: null,
  },
  {
    value: 3,
    orderStatusCode: '03',
    label: 'Aprovada',
    OrderStatusDescription: null,
  },
  {
    value: 4,
    orderStatusCode: '04',
    label: 'Recusada',
    OrderStatusDescription: null,
  },
  {
    value: 5,
    orderStatusCode: '05',
    label: 'Cancelada',
    OrderStatusDescription: null,
  },
  {
    value: 6,
    orderStatusCode: '06',
    label: 'Pendente',
    OrderStatusDescription: null,
  },
];
