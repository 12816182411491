import { Image, Text, View } from '@react-pdf/renderer';

import oxpay from '../../../../../../Assets/oxpay.png';
import { formatDocument } from '../../../../../../utils/formatDocument';
import { styles } from '../styles';

export function ExtractTemplateHeader({ user }) {
  const name =
    user?.fantasy_name ??
    user?.company?.social_name ??
    user?.company?.fantasy_name ??
    '';

  return (
    <View style={styles.header}>
      <Image src={oxpay} style={styles.iconOx} />
      <View id="info" style={styles.info}>
        <Text style={styles.title}>{name}</Text>
        <View id="infoCompany" style={styles.infoCompany}>
          <View style={styles.row}>
            <Text style={styles.bold}>CNPJ: </Text>
            <Text style={styles.text}>
              {user?.cnpj ?? formatDocument(user?.document) ?? ''}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Agência: </Text>
            <Text style={styles.text}>
              {user?.company?.swap_account?.branch ?? ''}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Conta: </Text>
            <Text style={styles.text}>
              {user?.company?.swap_account?.account ?? ''}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
