import { Text, View } from '@react-pdf/renderer';

import { formatDateSP } from '../../../../../../utils/formatDate';
import { styles } from '../styles';

export function ExtractTemplateFooter({ company }) {
  const date = new Date();

  return (
    <View style={styles.footer} fixed>
      <View style={styles.separator} />
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.rowContent}>
            <Text style={styles.bold}>Oxpay</Text>
            <Text style={styles.text}> - CNPJ 38.927.293/0001-20</Text>
          </View>
          <Text style={styles.text}>
            Extrato gerado no dia {formatDateSP(date)}
          </Text>
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Pág. ${pageNumber} de ${totalPages}`
          }
          fixed // Mantém o footer fixo em todas as páginas
        />
      </View>
    </View>
  );
}
