import axios from 'axios';
import { apiGet } from '../../../../services/api';
import { formatDocumentNumbers } from '../../../../utils/formatDocument';
import { formatGateway } from '../../../../utils/formatGateway';
import { formatName } from '../../../../utils/formatName';
import { anticipationSchema } from '../../Schemas/anticipationSchema';
import { cardSchema } from '../../Schemas/cardSchema';
import { collectedAnticipationSchema } from '../../Schemas/collectedAnticipationSchema';
import { receiveSchema } from '../../Schemas/receiveSchema';
import { slipSchema } from '../../Schemas/slipSchema';
import { transferSchema } from '../../Schemas/transferSchema';

const findValue = (obj, objectKey, fieldKey) => {
  if (objectKey) {
    if (obj[objectKey] && obj[objectKey].hasOwnProperty(fieldKey)) {
      return obj[objectKey][fieldKey];
    } else if (!obj[objectKey].hasOwnProperty(fieldKey)) {
      return fieldKey === 'bank_code' ? 'OX' : undefined;
    }
  } else {
    if (obj.hasOwnProperty(fieldKey)) return obj[fieldKey];
    for (let k in obj) {
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        const found = findValue(obj[k], null, fieldKey);
        if (found !== undefined) return found;
      }
    }
  }
  return undefined;
};

export async function processData(schema, data, idOrderOrBallance) {
  let result = {
    details: [],
    destiny: [],
    origin: [],
  };

  if (
    idOrderOrBallance &&
    data.movement.method === '07' &&
    data.consignation_collections.length
  )
    data = normalizeAnticipationData(data, idOrderOrBallance);

  data.order.bank_code = {
    bank_code: formatGateway(data.movement.gateway),
    company_name:
      data.company?.social_reason ||
      data.company?.social_name ||
      data.company?.fantasy_name ||
      data.company?.accountable,
  };

  if (data.movement.method === '08') {
    try {
      const { bank_name, recipient_name, bank_code, expiration_date } =
        await getBankSlipSimulate(data.movement.bank_slip);

      data.movement.bank_name_recipient = bank_name;
      data.movement.name = recipient_name;
      data.movement.bank_code = bank_code;
      data.movement.expiration_date = expiration_date;
    } catch (error) {
      console.error(error);
    }
  }

  for (const [key, fields] of Object.entries(schema)) {
    for (const { object, field, translate, handle } of fields) {
      let value;
      if (Array.isArray(field)) {
        for (let f in field) {
          value = findValue(data, object, field[f]);
          if (!!value) break;
        }
      } else value = findValue(data, object, field);

      if (value !== undefined) {
        const processedValue = handle ? await handle(value) : value;
        result[key].push({
          field: translate,
          value: processedValue,
          order: fields.indexOf(field),
        });
      }
    }
  }

  for (const key of Object.keys(result)) {
    result[key].sort((a, b) => a.order - b.order);
  }

  return filterArrays(result);
}

function filterArrays(obj) {
  let resultado = {};

  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      resultado[key] = obj[key];
    }
  }

  return resultado;
}

export function handleSchema(data, collection) {
  return data.value.movement.method === '12' &&
    data.value.used_consignable_limits.length
    ? anticipationSchema
    : !!collection &&
      data.value.movement.method === '07' &&
      data.value.consignation_collections.length
    ? collectedAnticipationSchema
    : data.value.order.type === 4 || data.value.order.type === 6
    ? receiveSchema
    : data.value.movement.method === '13'
    ? cardSchema
    : data.value.movement.method === '08'
    ? slipSchema
    : transferSchema;
}

function normalizeAnticipationData(data, idOrderOrBallance) {
  const sumArrayValues = (array, fields) => {
    return array.reduce((acc, item) => {
      fields.forEach((field) => {
        acc[field] = (acc[field] || 0) + (item[field] || 0);
      });
      return acc;
    }, {});
  };

  const normalizeArrayToObject = (array, sumFields = []) => {
    if (!Array.isArray(array) || array.length === 0) return null;
    if (array.length === 1) return array[0];
    return sumFields.length > 0
      ? sumArrayValues(array, sumFields)
      : array.reduce((acc, item) => ({ ...acc, ...item }), {});
  };

  const findOldestWithPendingAmount = (array) => {
    if (!Array.isArray(array) || array.length === 0) return null;
    return (
      array
        .filter((item) => (item.pending_amount || 0) > 0)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0]
        ?.created_at || null
    );
  };

  const normalizedData = { ...data };

  if (Array.isArray(data.consignable_limits)) {
    normalizedData.consignable_limits = normalizeArrayToObject(
      data.consignable_limits,
    );
  }

  if (Array.isArray(data.used_consignable_limits)) {
    normalizedData.used_consignable_limits = normalizeArrayToObject(
      data.used_consignable_limits,
      [
        'amount',
        'fee_amount',
        'billed_amount',
        'pending_amount',
        'pending_fee_amount',
      ],
    );

    // Atualizar o total de 'amount' para incluir o 'fee_amount'
    if (normalizedData.used_consignable_limits) {
      normalizedData.used_consignable_limits.total_used =
        (normalizedData.used_consignable_limits.amount || 0) +
        (normalizedData.used_consignable_limits.fee_amount || 0);
    }

    // Identificar a data de 'created_at' mais antiga com pendências
    normalizedData.used_consignable_limits.oldest_pending_date =
      findOldestWithPendingAmount(data.used_consignable_limits);
  }

  if (Array.isArray(data.consignation_collections)) {
    const collections = data.consignation_collections.filter(
      (i) => i.consignation_bill_balance_id <= idOrderOrBallance,
    );
    normalizedData.consignation_collections = normalizeArrayToObject(
      collections,
      [
        'amount',
        'fee_amount',
        'billed_amount',
        'pending_amount',
        'pending_fee_amount',
      ],
    );
  }

  if (normalizedData.used_consignable_limits) {
    const totalPendingAmount =
      (normalizedData.used_consignable_limits.pending_amount || 0) +
      (normalizedData.used_consignable_limits.pending_fee_amount || 0);

    const totalCollectedAmount =
      (normalizedData.consignation_collections?.amount || 0) +
      (normalizedData.consignation_collections?.fee_amount || 0);

    const totalOrderAmount = normalizedData.order?.total_amount || 0;

    normalizedData.used_consignable_limits.pending_amount =
      totalPendingAmount - totalCollectedAmount - totalOrderAmount;

    // Garantir que pending_amount não fique negativo
    normalizedData.used_consignable_limits.pending_amount = Math.max(
      0,
      normalizedData.used_consignable_limits.pending_amount,
    );
  }

  normalizedData.used_consignable_limits = {
    ...normalizedData.used_consignable_limits,
    remaining_amount:
      normalizedData.used_consignable_limits.total_used -
        normalizedData.consignation_collections.amount || 0,
  };

  return normalizedData;
}

export async function getBank(bank_code) {
  try {
    const bankName = handleInstitutionName(bank_code?.bank_code || bank_code);

    if (!!bankName) return bankName;
    else {
      const { data } = await axios.get(`https://brasilapi.com.br/api/banks/v1`);

      let nameBank = data.find((bank) => {
        if (bank_code.length === 5 || bank_code?.bank_code?.length === 5) {
          return (
            bank.code === Number(bank_code) ||
            bank.code === Number(bank_code?.bank_code) ||
            bank.ispb?.slice(0, 5) === bank_code ||
            bank.ispb.slice(0, 5) === bank_code?.bank_code
          );
        } else {
          return (
            bank.code === Number(bank_code) ||
            bank.code === Number(bank_code?.bank_code) ||
            bank.ispb === bank_code ||
            bank.ispb === bank_code?.bank_code
          );
        }
      })?.['name'];

      return nameBank;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getPixSimulate(document, token) {
  try {
    const { name, bank_name, bank_isbp } = await apiGet(
      `/Simulate/Pix/Key/${formatDocumentNumbers(document)}`,
    ).then((res) => res.data);

    return {
      name,
      bank_name,
      bank_code: bank_isbp,
    };
  } catch (error) {
    console.error(error);
  }
}

export async function getBankSlipSimulate(barcode) {
  try {
    const slip = await apiGet(`/Simulate/bankslip/${barcode}`).then(
      (res) => res.data,
    );

    return {
      recipient_name: slip?.details?.recipient_name || undefined,
      bank_name: slip?.barcode_details?.bank_name || undefined,
      bank_code: slip?.barcode_details?.bank_code || undefined,
      expiration_date: slip?.barcode_details?.expiration_date || undefined,
    };
  } catch (error) {
    console.error(error);
  }
}

export function handleBankCodeName(bank_code) {
  const name = bank_code?.bank_code || bank_code;

  if (bank_code?.company_name) return formatName(bank_code.company_name);

  switch (name) {
    case '31680151':
      return 'Swap';
    case 'OX':
      return 'Oxpay';

    default:
      return formatName(bank_code.company_name);
  }
}

function handleInstitutionName(code) {
  switch (code) {
    case '31680151':
      return 'Swap Meios de Pagamentos S.A';
    case 'OX':
    case 'CONSI':
    case 'CONSIGN':
      return 'Ox Serviços de tecnologia LTDA';

    default:
      return null;
  }
}

export function proofHandleTitle(type) {
  switch (type) {
    case '08':
      return 'Pagamento';
    case '11':
      return 'Estorno';
    case '12':
      return 'Antecipação realizada com sucesso!';
    default:
      return 'Transferência';
  }
}
