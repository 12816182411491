import React, { useEffect, useState } from 'react';
import useSurveys from '../../../hooks/useSurveys';
import { useUser } from '../../../hooks/useUser';
import styles from '../styles.module.scss';

const ModalForm = () => {
  const { surveyForm, setSurveyForm, respondSurvey, notRespondSurvey } =
    useSurveys();
  const [fields, setFields] = useState([]);
  const { user, userLogout } = useUser();

  useEffect(() => {
    if (surveyForm?.survey?.fields) {
      const initializedFields = surveyForm.survey.fields.map((field) => ({
        ...field,
        value: field.value || '',
      }));
      setFields(initializedFields);
    }
  }, [surveyForm]);
  const onClean = () => {
    setFields([]);
  };
  const handleInputChange = (id, value) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, value } : field,
      ),
    );
  };

  const onLogout = async () => {
    await notRespondSurvey(
      surveyForm?.survey?.id,
      user?.customer_id,
      surveyForm?.survey,
    );
    onClean();
    setSurveyForm({
      open: false,
      survey: false,
    });
    userLogout();
  };

  const onClose = async () => {
    await notRespondSurvey(
      surveyForm?.survey?.id,
      user?.customer_id,
      surveyForm?.survey,
    );
    onClean();
    setSurveyForm({
      open: false,
      survey: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const id = surveyForm?.survey?.id;
    const userId = user?.customer?.customer_id;

    const response = {
      userId,
      customer: {
        name: `${user?.customer?.first_name} ${user?.customer?.last_name}`,
        document: user?.customer?.document,
        email: user?.customer?.email,
        date: new Date(),
      },
      fields,
    };

    await respondSurvey(id, userId, response);
    onClose();
  };

  return (
    <div
      className={surveyForm?.open ? styles.modalOverlay : styles.modalOverlay_}
    >
      <div className={styles.modalContent_form}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2 className={styles.title}>Feedback</h2>
        <p className={styles.heading}>{surveyForm?.survey?.title}</p>
        <p className={styles.description}>{surveyForm?.survey?.description}</p>
        <form onSubmit={handleSubmit} className={styles.form_}>
          {fields.map(({ id, type, name, value }, index) => (
            <div key={id} className={styles.questionBlock_}>
              <label className={styles.questionText}>
                {index + 1}. {name}
              </label>
              <input
                type={type}
                className={styles.answerBox}
                placeholder="Sua resposta"
                value={value}
                required
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </div>
          ))}
          <div className={styles.wrapper_buttons}>
            <button type="submit" className={styles.submitButton}>
              Enviar
            </button>
            {surveyForm?.survey?.action === '2' && (
              <button className={styles.submitButton} onClick={onLogout}>
                Sair
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalForm;
