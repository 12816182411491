import * as XLSX from 'xlsx';

export class ExcelGenerator {
  static generateExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    return XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  }
}
