import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { MdPayments } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Loader, SelectPicker } from 'rsuite';
import { useNotification } from '../../../../../../hooks/useNotifications';
import { useOrder } from '../../../../../../hooks/useOrder';
import { usePayment } from '../../../../../../hooks/usePayment';
import { useUser } from '../../../../../../hooks/useUser';
import { formatDateNoSubTime } from '../../../../../../utils/formatDate';
import { formatMoney } from '../../../../../../utils/formatMoney';
import { IconDate, IconLike, IconMoney, IconPerson, IconProof } from '../icons';
import { IconBook } from '../PaymentFile/icons';
import { parseCurrencyToNumber } from '../PaymentPix/utils/parseCurrencyToNumber';
import {
  IconDate as IconCalendar,
  IconDocument,
  IconValue,
} from '../PaymentTransfer/icons';
import { addHoursAndFormat } from '../PaymentTransfer/utils/addHoursAndFormat';
import FormGroup from './FormGroup';
import { IconAccount, IconBranch, IconDigit, IconUser } from './icons';
import styles from './styles.module.scss';
import { Validate } from './utils/validate';

const PaymentBank = () => {
  const {
    stepPayment,
    setStepPayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    setErrosSteps,
    banks,
  } = usePayment();

  const { addNotification } = useNotification();
  const [clear] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const { user } = useUser();
  const userIsAdmin = [13, 10, 12, 14].every((item) =>
    user?.permission.includes(item),
  );
  const { fetchTransferOrder, fetchOrderKeyValidation, fetchOrderKey } =
    useOrder();
  const history = useHistory();

  const handleProof = () => {
    history.push({
      pathname: `/menu/proof/${finishPayment.key}`,
      state: {
        ...finishPayment,
      },
    });
    clearStatePayment();
  };
  const handleOrderKey = () => {
    history.push({
      pathname: `/menu/order/${finishPayment.key}`,
    });
    clearStatePayment();
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(Validate),
    defaultValues: {
      date: payment?.date
        ? payment?.date
        : new Date().toISOString().split('T')[0],
      name: payment?.name || '',
      document: payment?.document ? payment?.document.replace(/\D/g, '') : '',
      amount: payment?.amount || '',
      bank_code: payment?.bank_code || '',
      account: payment?.account || '',
      branch: payment?.branch || '',
      branch_digit: payment?.branch_digit || '',
      account_digit: payment?.account_digit || '',
      account_type: payment?.account_type || '',
      description: payment?.description || '',
    },
  });

  const formDate = watch('date');

  const onSubmit = async (values) => {
    const { amount, bank_code } = values;
    const fee =
      bank_code === 'OX'
        ? user.company.internal_transfer_fee
        : user.company.transfer_fee;
    try {
      const paymentInfo = values;
      if (values?.date !== today) {
        paymentInfo.date = values?.date;
      }
      setPayment({ ...paymentInfo, fee, total_amount: amount });
      setStepPayment(2);
      setErrosSteps({
        status: 'process',
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onPayment = async () => {
    setLoading({ paymentOrder: true });
    try {
      const order = await fetchTransferOrder({
        body: {
          description: payment?.description,
          movements: [
            {
              additional_data: payment?.description,
              name: payment?.name,
              document: payment?.document.replace(/\D/g, ''),
              amount: parseCurrencyToNumber(payment?.amount),
              bank_code: payment?.bank_code,
              account: payment?.account,
              account_digit: payment?.account_digit,
              branch: payment?.branch,
              ...(!!payment?.branch_digit && {
                branch_digit: payment?.branch_digit,
              }),
              account_type: payment?.account_type,
              ...(payment?.date && {
                date: addHoursAndFormat(payment.date, 3),
              }),
            },
          ],
        },
        isManual: true,
        isSalary: false,
      });

      if (order?.key) {
        await fetchOrderKeyValidation(order?.key, 3, '6');
        const { movements } = await fetchOrderKey(order?.key);
        if (movements[0]) {
          setFinishPayment({
            movements: movements[0],
            key: order.key,
            orderKey: order.key,
          });
          setStepPayment(3);
        }
      }
    } catch (error) {
      console.log(error);
      addNotification(
        <>
          Ocorreu um erro ao tentar realizar a transferência! <br />
          Por favor, verifique a chave pix e tente novamente.
        </>,
        2,
        'Não foi possível realizar a transferência!',
        true,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {stepPayment === 1 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_wrapper}>
            <FormGroup
              name="name"
              label={
                <>
                  <strong>Nome ou Razão Social</strong>
                </>
              }
              type="text"
              data={{ value: payment?.name }}
              placeholder="Nome Completo ou Razão Social"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconUser}
            />
            <FormGroup
              name="document"
              label={
                <>
                  <strong>Documento </strong> (CPF / CNPJ):
                </>
              }
              type="text"
              data={{ value: payment?.document }}
              placeholder={'999.999.999-99'}
              disabled={!loading?.paymentOrder ? false : true}
              mask={['999.999.999-99', '99.999.999/9999-99']}
              register={register}
              errors={errors}
              setValue={setValue}
              clean={clear}
              Icon={IconDocument}
            />
            <FormGroup
              name="amount"
              label={
                <>
                  <strong>Valor</strong>
                </>
              }
              mask={[
                '9,99',
                '99,99',
                '999,99',
                '9.999,99',
                '99.999,99',
                '999.999,99',
                '9.999.999,99',
              ]}
              type="text"
              data={{ value: payment?.amount }}
              placeholder="R$ 0,00"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconValue}
            />

            <FormGroup
              name="date"
              label={
                <>
                  <strong>Quando: </strong>{' '}
                  {today === formDate ? 'Hoje' : 'Agendado'}
                </>
              }
              type="date"
              placeholder="Data"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              min={new Date().toISOString().split('T')[0]}
              errors={errors}
              setValue={setValue}
              data={{
                value: payment.date ?? new Date().toISOString().split('T')[0],
              }}
              Icon={IconCalendar}
            />
          </div>
          <div className={styles.form_wrapper__}>
            <div className={styles.select_}>
              <Controller
                name="bank_code"
                control={control}
                {...register('bank_code')}
                render={({ field }) => (
                  <SelectPicker
                    data={banks}
                    style={{
                      width: '100%',
                    }}
                    size="lg"
                    placeholder="Banco"
                    defaultValue={
                      payment?.bank_code ? payment?.bank_code : null
                    }
                    id={field.item}
                    onChange={(value) => field.onChange(value)}
                    disabled={!loading.paymentOrder ? false : true}
                  />
                )}
              />
              {errors?.bank_code && (
                <span className={styles.error__input}>
                  {errors['bank_code'].message}
                </span>
              )}
            </div>
            <div className={styles.select_}>
              <Controller
                name="account_type"
                control={control}
                {...register('account_type')}
                render={({ field }) => (
                  <SelectPicker
                    data={[
                      {
                        value: 'CC',
                        label: 'Conta Corrente',
                      },
                      {
                        value: 'PP',
                        label: 'Conta Poupança',
                      },
                    ]}
                    style={{
                      width: '100%',
                    }}
                    size="lg"
                    defaultValue={payment.account_type}
                    searchable={false}
                    placeholder="Tipo da conta"
                    onChange={(value) => field.onChange(value)}
                    disabled={!loading.paymentOrder ? false : true}
                  />
                )}
              />
              {errors?.account_type && (
                <span className={styles.error__input}>
                  {errors['account_type'].message}
                </span>
              )}
            </div>
          </div>
          <div className={styles.form_wrapper_account}>
            <FormGroup
              name="branch"
              label={
                <>
                  <strong>Agência</strong>
                </>
              }
              type="text"
              placeholder="0000"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconBranch}
            />

            <FormGroup
              name="branch_digit"
              label={
                <>
                  <strong>Digito</strong>
                </>
              }
              type="text"
              placeholder="X"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              maxLength={1}
              Icon={IconDigit}
            />
            <FormGroup
              name="account"
              label={
                <>
                  <strong>Número da conta</strong>
                </>
              }
              type="text"
              placeholder="0000000"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconAccount}
            />
            <FormGroup
              name="account_digit"
              label={
                <>
                  <strong>Digito</strong>
                </>
              }
              type="text"
              placeholder="X"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              maxLength={1}
              Icon={IconDigit}
            />
          </div>
          <div className={styles.form_wrapper_}>
            <FormGroup
              name="description"
              label={
                <>
                  <strong>Descrição: </strong>
                </>
              }
              type="text"
              placeholder="Pagamento"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              data={{ value: payment?.description }}
              Icon={IconBook}
            />
            <div className={styles.buttons_form}>
              <button
                type="button"
                disabled={loading.paymentOrder}
                className={styles.button_cancel}
                onClick={clearStatePayment}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={styles.button}
                disabled={loading.paymentOrder}
              >
                Confirmar
                {loading.paymentOrder && <Loader size="xs " />}
              </button>
            </div>
          </div>
        </form>
      )}

      {stepPayment === 2 && (
        <div className={styles.description}>
          <h1 className={styles.title}>
            Foi criada uma <strong>transferência para:</strong>
          </h1>

          {payment && (
            <>
              <div className={styles.description_payment}>
                <div className={styles.description}>
                  <div className={styles.header_info}>
                    <div className={styles.icon}>
                      <IconPerson />
                    </div>

                    <div className={styles.info}>
                      <div className={styles.title_info}>
                        Pessoa/Instituição:
                      </div>
                      {payment.name === '---' ? (
                        <div className={styles.preview}>
                          * Dados não carregados
                        </div>
                      ) : (
                        <div className={styles.title_description}>
                          {payment.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className={styles.line_info} />
                  <div className={styles.header_info_wrapper}>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconMoney />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Valor:</div>

                        <div className={styles.title_description}>
                          {formatMoney(parseCurrencyToNumber(payment.amount))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Data:</div>

                        <div className={styles.title_description}>
                          {formatDateNoSubTime(payment.date, 'dd/MM/yyyy')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.box_info}>
                    <span>
                      Banco:
                      <p>
                        {banks &&
                          banks.find((item) => item.value === payment.bank_code)
                            .label}
                      </p>
                    </span>
                    <span>
                      Agência:
                      <p>
                        {` ${payment.branch} ${
                          payment.branch_digit ? `-${payment.branch_digit}` : ''
                        } `}
                      </p>
                    </span>

                    <span>
                      Conta:
                      <p>
                        {` ${payment.account} ${
                          payment.account_digit
                            ? `-${payment.account_digit}`
                            : ''
                        } `}
                      </p>
                    </span>

                    {payment?.error_description && (
                      <div className={styles.error_preview}>
                        {payment.error_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.block} />
                <div className={styles.values_container}>
                  <div className={styles.values}>
                    <IconProof />
                    <span className={styles.title_amount}>Valor Total:</span>
                    <span className={styles.amount}>
                      {formatMoney(
                        Number(parseCurrencyToNumber(payment.amount)) +
                          Number(user.company.transfer_fee),
                      )}
                    </span>
                    <div className={styles.fee}>
                      <span>* Taxa:</span>
                      <p>{formatMoney(user.company.transfer_fee)}</p>
                    </div>
                  </div>

                  <div className={styles.buttons}>
                    <button
                      type="button"
                      className={styles.button_cancel}
                      disabled={!loading.paymentOrder ? false : true}
                      onClick={() => setStepPayment(1)}
                    >
                      Cancelar
                    </button>
                    {payment?.status?.id !== 7 && (
                      <button
                        type="button"
                        className={styles.button}
                        disabled={!loading.paymentOrder ? false : true}
                        onClick={onPayment}
                      >
                        Confirmar
                        {loading.paymentOrder && <Loader size="xs " />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {stepPayment === 3 && (
        <div className={styles.description}>
          {finishPayment && (
            <>
              <div className={styles.description_finish}>
                <IconLike />
                <h2>
                  {userIsAdmin
                    ? 'Transferência finalizada!'
                    : 'Transferência Criada!'}
                </h2>
                <p>
                  {userIsAdmin ? (
                    <>
                      Tudo certo até aqui, agora você pode <br /> acompanhar
                      seus pagamentos.
                    </>
                  ) : (
                    <>
                      Tudo certo até aqui, <br /> agora é só esperar aprovação
                      dessa transação.
                    </>
                  )}
                </p>

                <div className={styles.buttons_finish}>
                  {finishPayment &&
                    finishPayment?.movements?.status?.id === 6 && (
                      <button
                        type="button"
                        disabled={loading.paymentOrder}
                        className={styles.button}
                        onClick={handleProof}
                      >
                        <MdPayments />
                        Comprovante
                      </button>
                    )}

                  {finishPayment &&
                    finishPayment?.movements?.status?.id !== 6 && (
                      <button
                        type="button"
                        disabled={loading.paymentOrder}
                        className={styles.button}
                        onClick={handleOrderKey}
                      >
                        <MdPayments />
                        Acompanhar Transferência
                      </button>
                    )}
                  <button
                    type="button"
                    disabled={loading.paymentOrder}
                    className={styles.button_cancel}
                    onClick={clearStatePayment}
                  >
                    <IoMdClose />
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { PaymentBank };
