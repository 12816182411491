import { Text, View } from '@react-pdf/renderer';

import {
  formatDateNoSubTime,
  formatDateSP,
} from '../../../../../../utils/formatDate';
import { formatMoney } from '../../../../../../utils/formatMoney';
import { styles } from '../styles';

export function ExtractTemplateDashboard({ period, report }) {
  return (
    <View style={styles.dashboard}>
      <View style={styles.row}>
        <Text style={styles.bigTitle}>Extrato do período</Text>
        <Text style={styles.textBlue}>
          {formatDateSP(period.dateInitial, 'dd/MM/yyyy')}
          {' a '}
          {formatDateNoSubTime(period.dateFinally)}
        </Text>
      </View>
      <View style={styles.separator} />
      <View style={styles.column}>
        <View style={styles.row}>
          <Text style={styles.bold}>Saldo inicial do período</Text>
          <Text style={styles.text}>{formatMoney(report?.saldoInicial)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>Total de entradas</Text>
          <Text style={styles.text}>{formatMoney(report?.totalEntradas)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>Total de saídas</Text>
          <Text style={styles.text}>{formatMoney(report?.totalSaidas)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Saldo final do período</Text>
          <Text style={styles.title}>{formatMoney(report?.saldoFinal)}</Text>
        </View>
        <View style={styles.separator} />
      </View>
    </View>
  );
}
