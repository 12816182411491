import { useState, useContext } from 'react';
import { db } from '../services/firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  where,
  query,
} from 'firebase/firestore';

import { StoreContext } from '../data/storeContext';
const SURVEYS_REF = process.env.REACT_APP_FIREBASE_SURVEYS;
const useSurveys = () => {
  const {
    setLoading,
    loading,
    surveys,
    setSurveys,
    setError,
    error,
    surveyNPC,
    setSurveyNPC,
    surveyCSAT,
    setSurveyCSAT,
    surveyForm,
    setSurveyForm,
    user,
  } = useContext(StoreContext);
  const [responses, setResponses] = useState([]);
  const [not_responses, setNotResponses] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fetchSurveys = async () => {
    try {
      setLoading({ surveys: true });
      const querySnapshot = await getDocs(collection(db, `${SURVEYS_REF}`));
      const surveyList = querySnapshot.docs
        .map((doc) => {
          const timestampInMillis =
            doc.data().created_at.seconds * 1000 +
            doc.data().created_at.nanoseconds / 1e6;
          const date = new Date(timestampInMillis);

          return {
            id: doc.id,
            ...doc.data(),
            date: date.toLocaleString(),
          };
        })
        .sort((a, b) => {
          const timeA =
            a.created_at.seconds * 1000 + a.created_at.nanoseconds / 1e6;
          const timeB =
            b.created_at.seconds * 1000 + b.created_at.nanoseconds / 1e6;
          return timeB - timeA;
        });
      setSurveys(surveyList);
    } catch (error) {
      console.error('não foi possível buscar as pesquisas ');
    } finally {
      setLoading(false);
    }
  };

  const fetchResponses = async (surveyId) => {
    const responsesRef = collection(db, `${SURVEYS_REF}/${surveyId}/responses`);
    const querySnapshot = await getDocs(responsesRef);
    const responseList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const not_responsesRef = collection(
      db,
      `${SURVEYS_REF}/${surveyId}/not_responses`,
    );
    const n_querySnapshot = await getDocs(not_responsesRef);
    const n_responseList = n_querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setResponses(responseList);

    setNotResponses(n_responseList);
  };

  const addSurvey = async (payload) => {
    try {
      setLoading({ surveys: true });
      await addDoc(collection(db, `${SURVEYS_REF}`), payload);
    } catch (error) {
      console.error('não foi possível adicionar as pesquisa');
    } finally {
      await fetchSurveys();
    }
  };

  const selectSurvey = async (survey) => {
    setSelectedSurvey(survey);
    await fetchResponses(survey.id);
  };

  const handleDeactivate = async (surveyId, surveyIsActive) => {
    try {
      const surveyRef = doc(db, `${SURVEYS_REF}`, surveyId);
      await updateDoc(surveyRef, {
        isActive: !surveyIsActive,
      });
      const text = surveyIsActive ? 'desativada' : 'ativada';
      alert(`Pesquisa ${text} com sucesso!`);

      await fetchSurveys();
    } catch (error) {
      console.error('Erro ao desativar pesquisa:', error);
    }
  };

  const handleDelete = async (surveyId) => {
    if (window.confirm('Tem certeza que deseja excluir esta pesquisa?')) {
      try {
        const surveyRef = doc(db, `${SURVEYS_REF}`, surveyId);
        await deleteDoc(surveyRef);

        await fetchSurveys();
      } catch (error) {
        console.error('Erro ao excluir pesquisa:', error);
      }
    }
  };

  const fetchActiveSurvey = async (action, userId) => {
    try {
      const surveysRef = collection(db, `${SURVEYS_REF}`);
      const q = query(
        surveysRef,
        where('isActive', '==', true),
        where('action', '==', action),
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      const survey = querySnapshot.docs[0].data();
      const surveyId = querySnapshot.docs[0].id;

      const userSurveyRef = doc(
        db,
        `${SURVEYS_REF}/${surveyId}/responses`,
        userId,
      );
      const userSurveySnap = await getDoc(userSurveyRef);

      if (userSurveySnap.exists()) {
        return null;
      }

      return { id: surveyId, ...survey };
    } catch (error) {
      console.error('Erro ao buscar pesquisa ativa:', error);
      return null;
    }
  };

  /**
   * Responde a uma pesquisa com base no surveyId.
   * @param {string} surveyId - ID da pesquisa.
   * @param {string} userId - ID do usuário respondendo.
   * @param {Object} response - Resposta do usuário (estrutura depende do tipo de pesquisa).
   * @returns {boolean} - Retorna true se a resposta for salva com sucesso, false caso contrário.
   */
  const respondSurvey = async (surveyId, userId, response) => {
    setLoading(true);
    setError(null);

    try {
      const surveyRef = doc(db, `${SURVEYS_REF}`, surveyId);

      const responsesRef = collection(surveyRef, 'responses');

      const q = query(responsesRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setLoading(false);
        setError('Usuário já respondeu a esta pesquisa.');
        return false;
      }

      await addDoc(responsesRef, {
        userId,
        ...response,
        answeredAt: new Date(),
      });

      setLoading(false);
      return true;
    } catch (err) {
      console.error('Erro ao responder à pesquisa:', err);
      setError('Erro ao responder à pesquisa.');
      setLoading(false);
      return false;
    }
  };

  const notRespondSurvey = async (surveyId, userId, survey) => {
    setLoading(true);
    setError(null);

    try {
      const surveyRef = doc(db, `${SURVEYS_REF}`, surveyId);

      const responsesRef = collection(surveyRef, 'not_responses');

      const q = query(responsesRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const responseList = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .find((item) => item.userId === userId);

      if (responseList && Number(responseList?.frequency) === 0) {
        try {
          if (
            typeof responseList?.id !== 'string' ||
            typeof surveyId !== 'string'
          ) {
            throw new Error('surveyId ou responseList.id não são strings.');
          }
          const surveyRefUpdate = doc(
            db,
            `${SURVEYS_REF}`,
            surveyId,
            'not_responses',
            responseList?.id,
          );

          await updateDoc(surveyRefUpdate, {
            frequency: survey?.frequency,
          });

          console.log('Documento atualizado com sucesso!');
          return;
        } catch (error) {
          console.error('Erro ao atualizar o documento:', error);
        }
      }

      if (!!responseList === false) {
        await addDoc(responsesRef, {
          userId,
          frequency: survey?.frequency,
          answeredAt: new Date(),
        });
        return false;
      }

      setLoading(false);
      return true;
    } catch (err) {
      console.error('Erro ao fechar à pesquisa:', err);
      setError('Erro ao fechar à pesquisa.');
      setLoading(false);
      return false;
    }
  };

  const triggerSurvey = async (action, user_) => {
    const userId = user?.customer?.customer_id ?? user_?.customer_id;
    const userCompany = user?.company_id ?? user_?.company_id;
    if (userCompany === 4 && !SURVEYS_REF.includes('dev')) {
      return false;
    }

    const survey = surveys.find((s) => s.action === action && s.isActive);

    if (!survey) {
      console.log('Nenhuma pesquisa ativa encontrada para essa ação.');
      return false;
    }
    const surveyId = survey?.id;
    const surveyRef = doc(db, `${SURVEYS_REF}`, surveyId);

    const responsesRef = collection(surveyRef, 'responses');

    const q = query(responsesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return false;
    }
    const not_responsesRef = collection(surveyRef, 'not_responses');

    const not_q = query(not_responsesRef, where('userId', '==', userId));
    const not_querySnapshot = await getDocs(not_q);
    const responseList = not_querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .find((item) => item.userId === userId);

    if (Number(responseList?.frequency) > 0) {
      try {
        if (
          typeof responseList?.id !== 'string' ||
          typeof surveyId !== 'string'
        ) {
          throw new Error('surveyId ou responseList.id não são strings.');
        }
        const surveyRefUpdate = doc(
          db,
          `${SURVEYS_REF}`,
          surveyId,
          'not_responses',
          responseList?.id,
        );
        const newNumber = Number(responseList?.frequency) - 1;

        await updateDoc(surveyRefUpdate, {
          frequency: newNumber,
        });

        console.log('Documento atualizado com sucesso!');
        return false;
      } catch (error) {
        console.error('Erro ao atualizar o documento:', error);
        return false;
      }
    } else {
      if (survey.type === '1') {
        setSurveyForm({
          open: true,
          survey,
        });
        return true;
      }
      if (survey.type === '2') {
        setSurveyCSAT({
          open: true,
          survey,
        });
        return true;
      }
      if (survey.type === '3') {
        setSurveyNPC({
          open: true,
          survey,
        });
        return true;
      }
    }

    return false;
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentSurvey(null);
  };
  return {
    surveys,
    responses,
    not_responses,
    selectedSurvey,
    addSurvey,
    selectSurvey,
    handleDeactivate,
    handleDelete,
    fetchSurveys,
    loading,
    fetchActiveSurvey,
    error,
    respondSurvey,
    notRespondSurvey,
    currentSurvey,
    isModalOpen,
    closeModal,
    triggerSurvey,
    surveyNPC,
    setSurveyNPC,
    surveyCSAT,
    setSurveyCSAT,
    surveyForm,
    setSurveyForm,
  };
};

export default useSurveys;
