export function formatDashboard(data) {
  const saldoInicial =
    data[data.length - 1].transactions[
      data[data.length - 1].transactions.length - 1
    ].before;
  let totalEntradas = 0;
  let totalSaidas = 0;

  data.forEach((t) => {
    if (t.after - t.before < 0)
      totalSaidas = totalSaidas + (t.before - t.after);
    else totalEntradas = totalEntradas + (t.after - t.before);
  });

  const saldoFinal = parseFloat(
    (data[data.length - 1].before + totalEntradas - totalSaidas).toFixed(2),
  );

  return {
    saldoInicial,
    totalEntradas,
    totalSaidas,
    saldoFinal,
  };
}
