import { useContext } from 'react';
import { StoreContext } from '../data/storeContext';
import {
  apiFormData,
  apiGet,
  apiPost,
  getApiUrl,
  getHeaders,
} from '../services/api';
import { formatOrder } from '../utils/formatOrder';
import { formatMovements } from '../utils/formatMovements';
import { totalAmount } from '../utils/totalAmount';
import { useUser } from './useUser';
import { useState } from 'react';
import { fireStoreAdd } from '../utils/firestoreActions';
import { useBalance } from '../hooks/useBalance';
import { useNotification } from './useNotifications';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { formatDocument } from '../utils/formatDocument';
import { checkDuplicateObjects } from '../utils/checkDuplicateObjects';
import useSurveys from './useSurveys';

export function useOrder() {
  const [progress, setProgress] = useState(false);
  const {
    orderKey,
    setOrderKey,

    error,
    setError,

    loading,
    setLoading,

    setOrderTransfer,
    orderTransfer,

    setOrder,
    order,

    setMessage,
    message,

    listOrder,

    setListMovement,
    listMovement,

    listConsignment,

    bankSlipInfo,
    setBankSlipInfo,

    user,
    //notify
    widgetTransfer,
    widgetConsignable,
    balance,
    customerListData,
  } = useContext(StoreContext);

  const { addNotification } = useNotification();
  const { fetchGetBalance, setBalanceConsignable } = useBalance();
  const queryClient = useQueryClient();
  const { triggerSurvey } = useSurveys();
  const { userLogout } = useUser();

  async function fetchOrderKey(body) {
    try {
      setOrder(null);
      setLoading({ order: true });
      setError(false);

      const { data } = await apiGet(`/order/${body}`);

      const { order, movements } = await data;

      setOrderKey({ key: order.key, status: order.status });
      movements.sort((a, b) => {
        return a.name < b.name ? -1 : true;
      });

      const order_formater = formatOrder({ ...order, movements: movements });
      const movements_formater = formatMovements(movements);
      const amount_formater = totalAmount(movements);

      return {
        order: order_formater,
        movements: movements_formater,
        amount: amount_formater,
        ...(order_formater.status?.id === 1 || order_formater.status?.id === 3
          ? { duplicate: checkDuplicateObjects(movements_formater) }
          : {}),
      };
    } catch (error) {
      console.log(error);
      const status = await error.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }

      setError({ order: 'Error Inesperado!' });
    } finally {
      setLoading(false);
    }
  }
  async function fetchDataOrderKey(body) {
    try {
      setOrder(null);

      const { data } = await apiGet(`/order/${body}`);

      const { order, movements } = await data;
      const fee = await movements.reduce((acc, item) => {
        return item?.bank_code === 'OX'
          ? acc + user.company.internal_transfer_fee
          : acc + user.company.transfer_fee;
      }, 0);
      const total_collected_consignation_amount = movements.reduce(
        (acc, item) => {
          return item?.collected_consignation_amount
            ? acc + item?.collected_consignation_amount
            : acc;
        },
        0,
      );
      setOrderKey({ key: order.key, status: order.status });
      movements.sort((a, b) => {
        return a.name < b.name ? -1 : true;
      });

      const movementFormat = formatMovements(movements);

      const orderFormat = formatOrder({ ...order, movements: movements });

      const all = movementFormat.length;
      const approved = movementFormat.filter((item) =>
        [6].includes(item.status.id),
      ).length;

      const erros = movementFormat.filter((item) =>
        [7].includes(item.status.id),
      ).length;
      const awaitingBalance = movementFormat.filter((item) =>
        [8].includes(item.status.id),
      ).length;
      const resumeTotal = movementFormat.reduce(
        (acc, cur) => {
          if (cur.status.id === 6) {
            acc.approved += cur?.amount || 0;
          } else if ([7].includes(cur.status.id)) {
            acc.errors += cur?.amount || 0;
          } else if (![6, 7, 1, 8, 5].includes(cur.status.id)) {
            acc.process += cur?.amount || 0;
          } else if (cur.status.id === 8) {
            acc.awaitingBalanceAmount += cur?.amount || 0;
          }
          return acc;
        },
        { approved: 0, errors: 0, process: 0, awaitingBalance: 0 },
      );
      const find_author =
        customerListData.find((user) => user.customer_id === order.user_id) ||
        false;
      const find_author_approve =
        customerListData.find(
          (user) => user.customer_id === order.approval_customer_id,
        ) || false;

      const author = find_author
        ? {
            name: `${find_author.first_name} ${find_author?.last_name}`,
            email: find_author.email,
            document: formatDocument(find_author?.document),
            customer_id: find_author.customer_id,
          }
        : false;
      const author_approve = find_author_approve
        ? {
            name: `${find_author_approve.first_name} ${find_author_approve?.last_name}`,
            email: find_author_approve.email,
            document: formatDocument(find_author_approve?.document),
            customer_id: find_author_approve.customer_id,
          }
        : false;
      return {
        author,
        author_approve,
        order: orderFormat,
        fee: fee ? fee : 0,
        total_collected_consignation_amount: total_collected_consignation_amount
          ? total_collected_consignation_amount
          : 0,
        movements: movementFormat,
        amount: totalAmount(movements),
        all,
        approved,
        erros,
        awaitingBalance,
        resumeTotal,
        ...(orderFormat.status.id === 1 || orderFormat.status.id === 3
          ? { duplicate: checkDuplicateObjects(movementFormat) }
          : {}),
      };
    } catch (error) {
      const { status } = await error.response;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
      setError({ order: 'Error Inesperado!' });
    } finally {
      setLoading(false);
    }
  }
  async function fetchDataListOrder(dateRanger) {
    try {
      const { data } = await axios.get(
        getApiUrl(
          `/order?count=400&skip=0&type=1&createdSince=${dateRanger?.dateInitial}&createdUntil=${dateRanger?.dateFinally}`,
        ),
        {
          headers: getHeaders(),
        },
      );

      const orders = data
        .map((item) => {
          const order = formatOrder(item);
          const movements = formatMovements(item?.movements);

          const fee = movements.reduce((acc, item) => {
            return item?.bank_code === 'OX'
              ? acc + user?.company?.internal_transfer_fee
              : acc + user?.company?.transfer_fee;
          }, 0);
          const total_collected_consignation_amount = movements.reduce(
            (acc, item) => {
              return item?.collected_consignation_amount
                ? acc + item?.collected_consignation_amount
                : acc;
            },
            0,
          );

          const duplicateObj =
            order?.status?.id === 1 || order?.status?.id === 3
              ? { duplicate: checkDuplicateObjects(movements) }
              : {};

          if (order) {
            return {
              ...order,
              fee: fee ? fee : 0,
              total_collected_consignation_amount:
                total_collected_consignation_amount
                  ? total_collected_consignation_amount
                  : 0,
              ...duplicateObj,
              movements,
            };
          }
          return null;
        })
        .filter((order) => Object.keys(order).length > 1);

      const orderISProcess = orders.find((order) =>
        [4].includes(order?.status?.id),
      );

      const orderISProcessList = orders.filter(
        (order) =>
          [4, 2].includes(order?.status?.id) ||
          order?.movements?.find((item) => !item?.bank_slip),
        order?.movements?.find((item) => item?.method !== '10'),
      );

      const orderISProcessMount = orderISProcessList.reduce(
        (acc, cur) => {
          acc.approved += cur.approvedAmount;
          acc.errors += cur.errosAmount;
          acc.process += cur.processAmount;
          acc.totalApproved += cur.approved;
          acc.totalErrors += cur.erros;
          acc.totalProcess += cur.process;
          acc.totalMovements += cur.total_moviments;
          acc.awaitingBalance += cur.awaitingBalance;
          acc.totalAwaitingBalance += cur.awaitingBalanceAmount;
          return acc;
        },
        {
          approved: 0,
          errors: 0,
          process: 0,
          totalApproved: 0,
          totalErrors: 0,
          totalProcess: 0,
          totalMovements: 0,
          awaitingBalance: 0,
          totalAwaitingBalance: 0,
        },
      );

      const orderProcess = orderISProcess?.awaitingBalance
        ? 1000 * 240
        : orderISProcess
        ? 1000 * 30
        : false;
      return { orders, orderProcess, orderISProcessMount, orderISProcessList };
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchDataListOrderConsignment(dateRanger) {
    try {
      const { data } = await axios.get(
        getApiUrl(
          `/order?count=400&skip=0&type=Consignable&createdSince=${dateRanger?.dateInitial}&createdUntil=${dateRanger?.dateFinally}`,
        ),
        {
          headers: getHeaders(),
        },
      );

      return data;
    } catch (error) {
      const { status } = await error.response;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Error servidor' });
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchTransferOrderAddBalanceConsignable(body) {
    try {
      setLoading({ balance: true });
      const { data } = await apiPost(`/order/transfer`, body, true);

      const { order } = await data;

      await fireStoreAdd(
        {
          action: 'NEW_TRANSFER_ADDED_BALANCE',
          date: new Date(),
        },
        user,
      );
      if (order) {
        fetchOrderKeyValidation(order.key, 0);

        addNotification(
          <>
            Deposito realizado com sucesso! <br />
            Aguarde um minuto que seu saldo será atualizado.
          </>,
          3,
          'Deposito',
          true,
        );
      }
      return order;
    } catch (error) {
      if (error?.response) {
        const status = await error?.response?.status;
        if (status === 401) {
          userLogout();

          setError({ order: 'Você não está autenticado' });
        }
        if (status === 500) {
          setError({ order: 'Error servidor' });
        }
        if (status === 400) {
          addNotification(
            'Infelizmente não foi possível realizar o depósito.',
            2,
            'Deposito não realizado',
            true,
          );
        }
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchTransferOrderAddedAdminBalanceConsignable(body) {
    try {
      setLoading({ balance: true });
      const { data } = await apiPost(
        `/Company/balance/consignable/add/${body}`,
      );
      const { after } = await data;
      if (after) {
        setBalanceConsignable(after);
      } else {
        setBalanceConsignable(0.0);
      }
      await fireStoreAdd(
        {
          action: 'NEW_TRANSFER_ADDED_BALANCE_ADM',
          date: new Date(),
        },
        user,
      );
      if (data) {
        addNotification(
          <>
            Deposito realizado com sucesso! <br />
            Aguarde um minuto que seu saldo será atualizado.
          </>,
          3,
          'Deposito',
          true,
        );
      }
      return data;
    } catch (error) {
      if (error?.response) {
        const status = await error?.response?.status;
        if (status === 401) {
          userLogout();

          setError({ order: 'Você não está autenticado' });
        }
        if (status === 500) {
          setError({ order: 'Error servidor' });
        }
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchTransferOrder({ body, isManual, isSalary }) {
    try {
      setOrderTransfer(null);
      setLoading({ newOrder: true });
      setError(false);
      const { data } = isManual
        ? await apiPost('/order/transfer', body)
        : await apiFormData(`/order/upload/transfer`, body, isSalary);

      const { order, movements } = await data;

      const fee = await movements.reduce((acc, item) => {
        return item?.bank_code === 'OX'
          ? acc + user.company.internal_transfer_fee
          : acc + user.company.transfer_fee;
      }, 0);
      setOrderTransfer({ ...order, fee: fee });

      isManual ? await triggerSurvey('5') : await triggerSurvey('3');

      return { ...order, fee, movements };
    } catch (error) {
      if (error?.response) {
        const status = await error.response.status;
        const data = await error.response.data;
        if (status === 401) {
          userLogout();
          setError({ order: 'Você não está autenticado' });
        }
        if (data?.error) {
          data?.error.code === 14 &&
            addNotification(
              'Ops! Você já enviou um arquivo com a mesma nomenclatura nas ultimas 24horas. Para sua segurança sua solicitação não foi processada.',
              2,
              true,
            );
          data?.error.code === 15 &&
            addNotification(
              <>
                Atenção: Para sua segurança sua solicitação não foi processada.
                Um arquivo com as mesmas características foi enviado
                recentemente.
              </>,
              2,
              true,
            );
          data?.error.code === 8 &&
            addNotification(
              <>
                Atenção: Para sua segurança infelizmente não foi possível
                realizar a criação dos pagamentos, <br /> por causa do horário
                de envio.
              </>,
              2,
              'Pagamento',
              true,
            );
        } else {
          const { errors } = await data;
          const [listErros] = [errors].map(function (obj) {
            return Object.keys(obj);
          });

          const responseErros = listErros.map((item) => {
            const stringItem = item.toString().replace(/[^0-9]/g, '');
            const parseItem = parseInt(stringItem);
            return parseItem + 3;
          });

          if (status === 400 && !isNaN(responseErros[0])) {
            addNotification(
              `Arquivo inválido nas linhas  ${responseErros}`,
              2,
              'Erro',

              true,
            );
          } else {
            addNotification('Arquivo inválido', 2, 'Erro', true);
          }

          if (status === 401) {
            userLogout();

            setError({ order: 'Você não está autenticado' });
          }
          if (status === 500) {
            setError({ order: 'Error servidor' });
          }
        }
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchTransferConsignment({ body, isManual, isSalary }) {
    try {
      setOrderTransfer(null);
      setLoading({ newOrder: true });
      setError(false);

      const { data } = isManual
        ? await apiPost('/order/consignable', body)
        : await apiFormData(`/order/upload/consignable`, body, isSalary);

      const { order } = await data;
      setOrderTransfer(order);
      if (data?.error) {
        data?.error.code === 14 &&
          addNotification(
            'Ops! Você já enviou um arquivo com a mesma nomenclatura nas ultimas 24horas. Para sua segurança sua solicitação não foi processada.',
            2,
            true,
          );
        data?.error.code === 15 &&
          addNotification(
            <>
              Atenção: Para sua segurança sua solicitação não foi processada. Um
              arquivo com as mesmas características foi enviado recentemente.
            </>,
            2,
            true,
          );
      }

      await fireStoreAdd(
        {
          action: 'NEW_CONSIGNMENT',
          date: new Date(),
        },
        user,
      );
      isManual ? await triggerSurvey('9') : await triggerSurvey('7');

      return { ...order, fee: 0 };
    } catch (error) {
      if (error?.response) {
        const { data, status } = await error.response;
        const { errors } = await data;

        const [listErros] = [errors].map(function (obj) {
          return Object.keys(obj);
        });

        const responseErros = listErros.map((item) => {
          const stringItem = item.toString().replace(/[^0-9]/g, '');
          const parseItem = parseInt(stringItem);
          return parseItem + 3;
        });

        if (status === 400 && !isNaN(responseErros[0])) {
          addNotification(
            `Arquivo inválido nas linhas  ${responseErros}`,
            2,
            'Erro',
            true,
          );
        } else {
          addNotification('Documento inválido', 2, 'Erro', true);
        }
      }
      const { status } = await error.response;
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      }
      if (status === 500) {
        userLogout();
        setError({ order: 'Error servidor' });
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchOrderKeyValidation(body, type, action = false) {
    try {
      setLoading({ newOrder: true });
      setError(false);

      if ([13].every((item) => user?.permission.includes(item))) {
        await fetchOrderKeyApprove(body, type);
        return;
      } else {
        if ([12].every((item) => user?.permission.includes(item))) {
          await apiPost(`/order/${body}/validate`, {
            orderKey: body,
          });
        }
        if (type === 2) {
          await queryClient.invalidateQueries(['ConsignableList']);
        }

        if (type === 1) {
          await queryClient.invalidateQueries([
            'OrdersList',
            'OrdersListPayment',
          ]);
        }

        if (type === 3) {
          await queryClient.invalidateQueries(['OrdersList']);
        }
      }
    } catch (error) {
      setError({ order: 'Não foi possível aprovar!' });
    } finally {
      setLoading(false);
      if (action) {
        await triggerSurvey(action);
      }
    }
  }
  async function fetchOrderKeyCancel(body, type) {
    try {
      setLoading({ newOrder: true });
      setError(false);
      await apiPost(`/order/${body}/cancel`, {
        orderKey: body,
      });
      if (type === 2) {
        await queryClient.invalidateQueries(['ConsignableList']);
      }

      if (type === 1) {
        await queryClient.invalidateQueries([
          'OrdersList',
          'OrdersListPayment',
        ]);
      }
      if (type === 3) {
        await queryClient.invalidateQueries(['OrdersList']);
      }
      await fireStoreAdd(
        {
          action: 'CANCEL_TRANSFER',
          date: new Date(),
        },
        user,
      );
      setOrderTransfer(false);
      if (type === 1) {
        addNotification(
          <>Pagamento foi cancelado com sucesso.</>,
          3,
          'Pagamento',
          true,
        );
      }

      if (type === 2) {
        addNotification(
          <>Antecipação foi cancelada com sucesso.</>,
          3,
          'Antecipação',
          true,
        );
      }
    } catch (error) {
      setError({ order: 'Não foi possível Cancelar!' });
    } finally {
      setLoading(false);
    }
  }
  async function fetchOrderKeyApprove(body, type) {
    try {
      setError(false);

      await apiPost(`/order/${body}/approve`, {
        orderKey: body,
      });
      if (type === 2) {
        await queryClient.invalidateQueries(['ConsignableList']);
      }

      if (type === 1) {
        await queryClient.invalidateQueries([
          'OrdersList',
          'OrdersListPayment',
        ]);
      }
      if (type === 0) {
        fetchOrderKey(body);
        setOrderTransfer(false);
        await fetchGetBalance();
      }

      if (type === 1) {
        addNotification(
          <>Pagamento foi aprovado com sucesso.</>,
          3,
          'Pagamento',
          true,
        );
        setOrderTransfer(false);
        fetchOrderKey(body);

        await fetchGetBalance();
      }

      if (type === 2) {
        addNotification(
          <>Antecipação foi liberada com sucesso.</>,
          3,
          'Antecipação',
          true,
        );
        await fetchGetBalance();
      }
      await fireStoreAdd(
        {
          action: 'APROVE_TRANSFER',
          date: new Date(),
        },
        user,
      );
    } catch (error) {
      setError({ order: 'Não foi possível aprovar!' });
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function fetchOrderPreview(orderKey) {
    try {
      setError(false);
      setLoading(true);

      await apiPost(`/Order/${orderKey}/Preview`);
    } catch (error) {
      const err = await error.response;
      if (err?.status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  async function fetchMovementList(paymentMethod, dateSince, dateUntil) {
    try {
      setLoading({ order: true });
      setError(false);
      setListMovement([]);
      const dateQuery =
        dateSince && dateUntil
          ? `&DateSince=${dateSince}&DateUntil=${dateUntil}`
          : '';
      const { data } = await apiGet(
        `/movement?PaymentMethod=${paymentMethod}${dateQuery}`,
      );
      await data
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .filter((item) => item?.amount);

      const formatMovement = formatMovements(data);
      setListMovement(formatMovement);
      return formatMovement;
    } catch (error) {
      const err = await error.response;
      if (err?.status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (err?.status === 500) {
        setError({ order: 'Error servidor' });
      }
      if (!err?.status) setError({ order: 'Error inesperado yyy' });
    } finally {
      setLoading(false);
    }
  }

  return {
    orderKey,
    loading,
    error,
    setError,
    orderTransfer,
    setOrderKey,
    setOrder,
    order,
    setBankSlipInfo,
    bankSlipInfo,
    setListMovement,
    listMovement,
    setMessage,
    message,
    listOrder,
    progress,
    setProgress,
    setOrderTransfer,
    listConsignment,
    setLoading,
    //notify
    widgetTransfer,

    widgetConsignable,
    balance,
    //
    fetchOrderKey,
    fetchOrderKeyCancel,
    fetchOrderKeyValidation,
    fetchOrderKeyApprove,
    fetchTransferOrder,
    fetchTransferConsignment,
    fetchTransferOrderAddBalanceConsignable,
    fetchTransferOrderAddedAdminBalanceConsignable,
    fetchDataListOrderConsignment,
    fetchDataListOrder,
    fetchDataOrderKey,
    fetchOrderPreview,
    fetchMovementList,
  };
}
