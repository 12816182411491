import React from 'react';
import { IconDownload } from '../../../../../components/Details/Icons';
import styles from '../styles.module.scss';

export const ExportButton = ({ title, onClick }) => (
  <button className={styles.button} onClick={onClick} name="exportPDF">
    <IconDownload color="var(--gray-400)" size={'1.2rem'} />
    {title}
  </button>
);
