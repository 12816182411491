import React, { useState } from 'react';
import { useNotification } from '../../../../hooks/useNotifications';
import { exportExcelInvoices } from '../../utils/exportExcel';
import exportInvoices from '../../utils/exportInvoice';
import {
  IconAction,
  IconCancel,
  IconCheck,
  IconExport,
  IconExportInvoices,
} from './icons';
import styles from './styles.module.scss';

export function ButtonMenuActions({
  listSelect,
  invoices,
  handleReceiptApprove,
  handleReceiptRefuse,
}) {
  const [active, setActive] = useState(false);
  const count_listSelect = listSelect.length;
  const { addNotification } = useNotification();

  const selectedInvoices = !!count_listSelect
    ? invoices.filter((v) => listSelect.includes(v?.key))
    : invoices;

  async function handleExportInvoices() {
    const { successCount, errorCount } = await exportInvoices(selectedInvoices);

    addNotification(
      <>
        Exportação finalizada! <br />
        Sucessos: {successCount}, Erros: {errorCount}.
      </>,
      3,
      'Processamento de exportação',
      true,
    );
  }

  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      Ações <IconAction size={'1.8rem'} />
      {active && (
        <div className={styles.menu}>
          {count_listSelect > 0 && (
            <>
              <button
                className={styles.button_plus}
                onClick={() => handleReceiptApprove()}
              >
                <IconCheck size={'1.5rem'} />
                Aprovar ({count_listSelect})
              </button>
              <button
                className={styles.button_plus}
                onClick={() => handleReceiptRefuse()}
              >
                <IconCancel size={'1.5rem'} />
                Reprovar ({count_listSelect})
              </button>
            </>
          )}
          <button
            className={styles.button_plus}
            onClick={() => exportExcelInvoices(selectedInvoices)}
          >
            <IconExport size={'1.5rem'} />
            {!!count_listSelect
              ? `Relatório Excel (${count_listSelect})`
              : 'Relatório Excel (Período)'}
          </button>
          {count_listSelect > 0 && (
            <>
              <button
                className={styles.button_plus}
                onClick={() => handleExportInvoices()}
              >
                <IconExportInvoices size={'1.5rem'} color="#666666" />
                Exportar Notas
              </button>
            </>
          )}
        </div>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
