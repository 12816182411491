import React, { useState } from 'react';
import { IconDownload } from '../../../../components/Details/Icons';
import { useUser } from '../../../../hooks/useUser';
import { ExportExcelExtract } from '../ExportExcelExtract';
import { ExportOFXExtract } from '../ExportOFXExtract';
import { ExportPDFExtract } from '../ExportPDFExtract';
import { ExportXMLExtract } from '../ExportXMLExtract';
import styles from './styles.module.scss';

export function ButtonMenuDownload({ list, model = [1, 2, 3, 4], period }) {
  const [active, setActive] = useState(false);
  const { user } = useUser();

  const templates = [
    {
      id: 1,
      title: 'Extrato de pagamento (Excel)',
      Component: ({ title }) => (
        <ExportExcelExtract list={list} title={title} />
      ),
    },
    {
      id: 2,
      title: 'Extrato de pagamento (XML)',
      Component: ({ title }) => <ExportXMLExtract list={list} title={title} />,
    },
    {
      id: 3,
      title: 'Extrato de pagamento (OFX)',
      Component: ({ title }) => <ExportOFXExtract list={list} title={title} />,
    },
    {
      id: 4,
      title: 'Extrato de pagamento (PDF)',
      Component: ({ title }) => (
        <ExportPDFExtract
          list={list}
          title={title}
          user={user}
          period={period}
        />
      ),
    },
  ];
  const filter = templates?.filter((item) => model.includes(item.id));

  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      <IconDownload color="var(--primary)" size={'2rem'} />
      {active && (
        <ul className={styles.menu}>
          {filter &&
            filter.map(({ id, title, Component }) => (
              <Component key={id} title={title} />
            ))}
        </ul>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
