import { parseISO, subHours } from 'date-fns';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

export const formatDate = (date, type = 'dd/MM/yyyy') => {
  const parsedDate = parseISO(date);
  const dateMinus3Hours = subHours(parsedDate, 3); // Subtract 3 hours from the date

  try {
    return format(dateMinus3Hours, type, {
      locale: ptBR,
    });
  } catch (error) {
    return '---';
  }
};
export const formatDateNoSubTime = (date, type = 'dd/MM/yyyy') => {
  const parsedDate = parseISO(date);
  try {
    return format(parsedDate, type, {
      locale: ptBR,
    });
  } catch (error) {
    return '---';
  }
};

export const formatDateExtract = (date, type = 'dd/MM/yyyy') => {
  const parsedDate = parseISO(date);
  const dateMinus3Hours = subHours(parsedDate, 3); // Subtract 3 hours from the date

  try {
    return format(dateMinus3Hours, type, {
      locale: ptBR,
    });
  } catch (error) {
    return '---';
  }
};

export const formatDateSP = (date, type = "dd MMM yyyy 'às' HH:mm") => {
  return format(new Date(date), type, {
    locale: ptBR,
    timeZone: 'America/Sao_Paulo',
  });
};
