import { addDays, differenceInDays, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiAlertTriangle, FiCheck } from 'react-icons/fi';
import { IoReloadOutline } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { CheckPicker, Loader } from 'rsuite';
import { DatePicker } from '../../components/DatePiker';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { useDate } from '../../hooks/useDate';
import { useNotification } from '../../hooks/useNotifications';
import { useOrder } from '../../hooks/useOrder';
import { usePagination } from '../../hooks/usePagination';
import { usePayment } from '../../hooks/usePayment';
import { useFetchOrderQuery } from '../../hooks/useQuery';
import { useTransfer } from '../../hooks/useTransfer';
import { useUser } from '../../hooks/useUser';
import { lower } from '../../utils/lower';
import { ButtonMenuDownload } from './Components/ButtonMenuDownload';
import { CardAmountAwait } from './Components/CardAmountAwait';
import { CardAmountMoney } from './Components/CardAmountMoney';
import { CardAmountPeople } from './Components/CardAmountPeople';
import { CardResumeSelect } from './Components/CardResumeSelect';
import { InputSearch } from './Components/InputSearch';
import PaymentTable from './Components/PaymentTable';
import StepsPayment from './Components/StepsPayment';
import { IsModal } from './Components/StepsPayment/Components/IsModal';
import { statusData } from './data';
import styles from './styles.module.scss';

import { ButtonMenuActions } from './Components/ButtonMenuActions';

const Transfer = () => {
  const [search, setSearch] = useState(false);
  const { clearStatePayment } = usePayment();
  const [, setCurrent] = useState(1);
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();
  const { datePayment, setDatePayment, dateRanger, setDateRanger } = useDate();
  const [time, setTime] = useState(false);
  const [viewSteps, setViewSteps] = useState(false);
  const { statusTransferSelect, setStatusTransferSelect } = useTransfer();
  const { user } = useUser();

  const history = useHistory();
  const { setOrderTransfer, fetchOrderKeyValidation, fetchOrderKeyCancel } =
    useOrder();
  const [listSelect, setListSelect] = useState([]);
  const [firstClick, setFirstClick] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (search) {
      setListSelect([]);
      setFirstClick([]);
      setStatusTransferSelect([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  useEffect(() => {
    if (
      !datePayment ||
      differenceInDays(new Date(datePayment[1]), new Date(datePayment[0])) < 90
    ) {
      let startDateString = addDays(datePayment[0], 1)
        .toISOString()
        .substring(0, 10);

      let endDateString = addDays(datePayment[1], 1)
        .toISOString()
        .substring(0, 10);

      let startDate = new Date(startDateString);

      startDate.setHours(-3, 0, 0);
      startDate = startDate.toISOString();

      let endDate = new Date(endDateString);

      endDate.setHours(20, 59, 59);
      endDate = endDate.toISOString();

      setDateRanger({
        dateInitial: startDate.toString().substring(0, 19) + '.0793547-03:00',
        dateFinally: endDate.toString().substring(0, 19) + '.0793547-03:00',
      });
    } else {
      addNotification(
        'A data não pode ter intervalo maior que 90 dias ',
        1,
        'Atenção',
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePayment]);

  const handleStatusChange = (value) => {
    setStatusTransferSelect(value);
  };
  const { data, isFetching } = useFetchOrderQuery(
    dateRanger?.dateInitial
      ? dateRanger
      : setDatePayment([subDays(new Date(), 6), new Date()]),
    time,
  );

  useEffect(() => {
    if (search) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (data?.orderProcess) {
      setTime(data?.orderProcess);
    } else {
      setTime(false);
    }
  }, [data]);
  async function handleReload() {
    await queryClient.invalidateQueries(['OrdersList']);
  }

  function handlePushRouter(id, status) {
    history.push({
      pathname: `/menu/order/${id}`,
      state: {
        status,
      },
    });
  }
  const dataCards = data?.orders ?? [];

  const filterList = data
    ? data?.orders
        .filter((item) => {
          if (statusTransferSelect.length === 0) return item;

          return statusTransferSelect.includes(item.status.id) && item;
        })
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return (
            lower(`${item['file_name']}`).includes(lower(search)) ||
            lower(`${item['description']}`).includes(lower(search))
          );
        })
    : [];
  const toggleSelect = (itemId) => {
    if (listSelect.includes(itemId)) {
      setListSelect(listSelect.filter((id) => id !== itemId));
    } else {
      setListSelect([...listSelect, itemId]);
    }
  };
  const selectAll = () => {
    const ids = filterList
      .filter((item) => [1, 3].includes(item?.status?.id))
      .filter((item) => item?.total_amount > 0)
      .map((item) => item.key);

    if (firstClick) {
      setListSelect([]);
      setListSelect(ids);
    } else {
      setListSelect([]);
    }

    // Toggle the firstClick state
    setFirstClick(!firstClick);
  };
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    !!filterList ? filterList : [],
    10,
    '/menu/transfer',
  );
  const handleViewSteps = () => {
    setViewSteps(!viewSteps);
    clearStatePayment();
    if (viewSteps) {
      setOrderTransfer(null);
      setCurrent(1);
    }
  };
  const resume_listSelect =
    listSelect.length > 0 &&
    listSelect
      .map((item) => {
        return filterList.find((order) => order.key === item);
      })
      .reduce(
        (acc, item) => {
          const movementsTotal = item?.movements.reduce(
            (movAcc, movement) => {
              if ([1, 3].includes(movement?.status?.id)) {
                return {
                  amount: movAcc?.amount + movement.amount,
                  count: movAcc?.count + 1,
                };
              }
              return {
                amount: movAcc?.amount,
                count: movAcc?.count,
              };
            },
            { amount: 0, count: 0 },
          );
          return {
            total_amount: acc.total_amount + movementsTotal?.amount,
            total_order: acc.total_order + movementsTotal?.count,
          };
        },
        { total_amount: 0, total_order: 0 },
      );
  async function handleSendPayment() {
    setLoading({ approve: true, cancel: false });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          const type = 0;

          await fetchOrderKeyValidation(key, type);
          successCount++;
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento dos pagamentos:', globalError);
    } finally {
      setLoading(false);
      handleReload();
      setListSelect([]);
      addNotification(
        <>
          Processamento finalizado! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Resumo do Processamento de aprovação',
        false,
      );
    }
  }
  async function handleSendCancel() {
    setLoading({ approve: false, cancel: true });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          const type = 0;

          await fetchOrderKeyCancel(key, type);
          successCount++;
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento dos pagamentos:', globalError);
    } finally {
      setLoading(false);
      handleReload();
      setListSelect([]);
      addNotification(
        <>
          Processamento finalizado! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Resumo do Processamento de cancelamento',
        false,
      );
    }
  }
  return (
    <div className={styles.container}>
      <IsModal />
      <div className={styles.header_style_}>
        <div className={styles.title_style}>Pagamentos</div>

        <div className={styles.buttons}>
          {data && (
            <ButtonMenuDownload model={[1, 2, 3]} isFetching={isFetching}>
              Modelos
            </ButtonMenuDownload>
          )}
          <div className={styles.separator}> </div>
          {user.permission.includes(25) && (
            <Link to="/menu/analyze" className={styles.send_}>
              <FiAlertTriangle size="1.125rem" color={'var(--gray-400)'} />
              Análise de Pagamentos
            </Link>
          )}
          {[10].every((item) => user?.permission.includes(item)) && (
            <button onClick={handleViewSteps} className={styles.send}>
              <AiOutlinePlus
                className={styles.icon}
                size="1.125rem"
                style={viewSteps && { rotate: '45deg' }}
              />
              {viewSteps ? 'Fechar' : 'Novo Pagamento'}
            </button>
          )}
        </div>
      </div>
      {viewSteps && <StepsPayment setViewSteps={setViewSteps} />}
      <div className={styles.container_arrow_cards}>
        <div className={styles.wrapper_cards_resume}>
          <CardAmountMoney
            data={dataCards}
            loading={isFetching}
            status={[0]}
            title={'Pagamentos Realizados'}
          />
          <CardAmountPeople
            data={dataCards}
            loading={isFetching}
            status={[0]}
            title={'Beneficiados'}
          />
          <CardAmountAwait
            data={dataCards}
            loading={isFetching}
            status={[0]}
            title={'Em aprovação'}
            handleStatusChange={handleStatusChange}
            statusTransferSelect={statusTransferSelect}
          />
        </div>
      </div>
      <div className={styles.content_list}>
        <div className={styles.title_list}>
          <header>
            <h3>Lista de Pagamentos</h3>
            <p>{data?.orders.length > 0 ? data?.orders.length : 0}</p>
          </header>
          <InputSearch
            label="Pesquisar (Arquivo, Descrição, CPF / CNPJ)"
            type="text"
            setValue={setSearch}
            value={search}
            Icon={RiSearch2Line}
            width="50%"
            disabled={isFetching}
          />
          <div className={styles.container_arrow_box}>
            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
        <div className={styles.wrapper_content}>
          <div className={styles.selectReload}>
            <div className={styles.wrapper_buttons_select}>
              <DatePicker
                setDate={setDatePayment}
                value={datePayment}
                style={{ width: '16rem' }}
                type={2}
                disabled={isFetching}
              />
              <CheckPicker
                data={isFetching ? [] : statusData}
                // style={{ maxWidth: '16rem', minWidth: '8rem' }}
                placeholder="Status"
                onChange={handleStatusChange}
                disabled={isFetching}
                cleanable={isFetching}
                defaultValue={statusTransferSelect}
                value={statusTransferSelect}
              />
            </div>
            <div className={styles.wrapper_acions}>
              {resume_listSelect?.total_order > 0 && (
                <>
                  <CardResumeSelect resume={resume_listSelect} />
                  <button
                    onClick={handleSendPayment}
                    className={styles.send_approve}
                  >
                    {loading.approve ? (
                      <Loader />
                    ) : (
                      <FiCheck size="1.125rem" color="var(--white)" />
                    )}
                    Aprovar
                  </button>
                </>
              )}
              {data?.orders.length > 0 && (
                <ButtonMenuActions
                  orders={data.orders}
                  listSelect={listSelect}
                  handleSendPayment={handleSendPayment}
                  handleSendCancel={handleSendCancel}
                />
              )}
            </div>
            <span className={styles.arrow}>
              {[16].every((item) => user?.permission.includes(item)) && (
                <button
                  disabled={!isFetching ? false : true}
                  className={styles.button_load_style}
                  onClick={handleReload}
                >
                  {isFetching ? (
                    <Loader />
                  ) : (
                    <IoReloadOutline size="1.3rem" color="var(--white)" />
                  )}
                </button>
              )}
            </span>
          </div>

          <div className={styles.wrapper_table}>
            <PaymentTable
              payments={currentItens}
              isFetching={isFetching}
              handlePushRouter={handlePushRouter}
              selectAll={selectAll}
              firstClick={firstClick}
              toggleSelect={toggleSelect}
              listSelect={listSelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
