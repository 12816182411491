import { Image, Text, View } from '@react-pdf/renderer';

import iconCardMaster from '../../../../Assets/iconCardMaster.png';
import { themes } from '../../Themes';
import { getStyleProof } from '../styles';

export function ProofTemplateDetails({ platform, proof, movement }) {
  const theme = themes[platform] || themes.default;
  const styles = getStyleProof(theme);

  return (
    <View style={styles.detailsSection}>
      {proof?.details
        ? proof.details.map((item, i) => (
            <View
              key={i}
              style={
                item.field === 'Id da transação' || item.field === 'Descrição'
                  ? styles.column
                  : styles.row
              }
            >
              <Text style={styles.label}>{item.field}</Text>
              {item.field === 'Status' && movement.method === '13' ? (
                <View style={styles.rowContent}>
                  <View
                    style={
                      item.value === 'Finalizado'
                        ? styles.statusGreen
                        : item.value === 'Cancelado' || item.value === 'Erro'
                        ? styles.statusRed
                        : styles.statusBlue
                    }
                  />
                  <Text style={styles.value}>{item.value}</Text>
                </View>
              ) : item.field === 'Cartão' && movement.method === '13' ? (
                <View style={styles.rowContent}>
                  <Image src={iconCardMaster} style={styles.iconCardMaster} />
                  <Text style={styles.value}>{item.value}</Text>
                </View>
              ) : (
                <Text style={styles.value}>{item.value}</Text>
              )}
            </View>
          ))
        : ''}
    </View>
  );
}
