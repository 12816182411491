import React from 'react';
import { MdDelete } from 'react-icons/md';
import { IconEyes } from '../../../../components/Details/Icons';
import { LoadingBall } from '../../../../components/UI/LoadingBall';
import useSurveys from '../../../../hooks/useSurveys';
import { formatDocument } from '../../../../utils/formatDocument';
import {
  actionsSurveys,
  frequencySurveys,
  options,
  typesSurveys,
} from '../../data';
import { ExportExcelSurveys } from '../ExportExcelSurveys';
import styles from './styles.module.scss';

const SurveyList = () => {
  const {
    surveys,
    responses,
    not_responses,
    selectedSurvey,
    handleDeactivate,
    handleDelete,
    selectSurvey,
    loading,
  } = useSurveys();
  console.log('🚀 ~ SurveyList ~ responses:', responses);
  console.log('🚀 ~ SurveyList ~ surveys:', surveys);
  console.log('🚀 ~ SurveyList ~ not_responses:', not_responses);

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
    );
    return date.toLocaleString();
  };
  const result = options.reduce((acc, option) => {
    const count = responses.filter(
      (feedback) => feedback.selectedFeedback === option,
    ).length;
    acc.push({ feedback: option, count });
    return acc;
  }, []);
  console.log('🚀 ~ SurveyList ~ result:', result);
  const listActions = surveys
    .filter((item) => {
      return item.isActive === true;
    })
    .map((item) => {
      return item?.action;
    });
  return (
    <div style={{ margin: '20px' }}>
      <div className={styles.header_style_}>
        <div className={styles.title_style}>Lista de Pesquisas</div>
      </div>
      {/* Tabela de pesquisas */}
      <table className={styles.table_container}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Titulo</th>
            <th>Descrição</th>
            <th>Data</th>
            <th>Tipo</th>
            <th>Ação</th>
            <th>Frequência</th>
            <th>Ativo</th>
            <th>Visualizar</th>
            <th>Gerir</th>
            <th>Apagar </th>
          </tr>
        </thead>
        <tbody>
          {surveys.length > 0 ? (
            surveys.map((survey) => (
              <tr
                style={{
                  background:
                    selectedSurvey?.id === survey?.id
                      ? 'var(--blue-50)'
                      : 'transparent',
                }}
                key={survey.id}
              >
                <td>{survey.name}</td>
                <td>{survey.title}</td>
                <td>{survey.description}</td>
                <td>{survey?.date} </td>
                <td>
                  {
                    typesSurveys.find((item) => item.id === survey.type)
                      .description
                  }
                </td>
                <td>
                  {survey?.action
                    ? actionsSurveys.find((item) => item.id === survey.action)
                        .description
                    : '---'}
                </td>
                <td>
                  {survey?.frequency
                    ? frequencySurveys.find(
                        (item) => item.id.toString() === survey.frequency,
                      )?.value
                    : '---'}
                </td>
                <td>
                  {survey.isActive ? (
                    <div className={styles.active} />
                  ) : (
                    <div className={styles.not_active} />
                  )}
                </td>
                <td>
                  <button
                    style={{ background: 'transparent' }}
                    onClick={() => selectSurvey(survey)}
                  >
                    <IconEyes size="1.3rem" color="var(--gray-500)" />
                  </button>
                </td>
                <td>
                  <button
                    style={{ background: 'transparent' }}
                    onClick={() => handleDeactivate(survey.id, survey.isActive)}
                    disabled={
                      survey.isActive === false &&
                      listActions.includes(survey.action)
                    }
                  >
                    {survey.isActive ? 'Desativar' : 'Ativar'}
                  </button>
                </td>
                <td>
                  <button
                    style={{ background: 'transparent' }}
                    onClick={() => handleDelete(survey.id)}
                  >
                    <MdDelete color="var(--red-500)" />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <>
              {loading?.surveys ? (
                <tr>
                  <td colSpan={9} className={styles['no-users']}>
                    <LoadingBall />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={9} className={styles['no-users']}>
                    Nenhuma pesquisa encontrada!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>

      {/* Respostas da pesquisa */}
      {selectedSurvey && (
        <div className={styles.box_response}>
          <header className={styles.box_header}>
            <h2 className={styles.title}>{selectedSurvey.name}</h2>
            {responses.length > 0 && selectedSurvey?.type === '2' && (
              <>
                <table className={styles.table_container}>
                  <thead>
                    <tr>
                      <th>Total</th>
                      <th>Respostas</th>
                      <th>Evasões</th>
                      {selectedSurvey?.options?.map((item) => (
                        <th key={item}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <th>{responses.length + not_responses.length}</th>
                    <th>{responses.length}</th>
                    <th>{not_responses.length}</th>
                    {result?.map((item) => (
                      <th key={item.feedback}>{item.count}</th>
                    ))}
                  </tbody>
                </table>
                <br />
              </>
            )}
            <span>
              Respostas: {responses?.length}
              {responses.length > 0 && (
                <ExportExcelSurveys
                  list={responses}
                  title={selectedSurvey?.name}
                  type={selectedSurvey?.type}
                />
              )}
            </span>
          </header>

          {responses.length > 0 && selectedSurvey?.type === '2' && (
            <>
              <br />
              <table className={styles.table_container}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Nome</th>
                    <th>Comentário</th>
                    <th>Resposta</th>
                    <th>Data</th>
                    <th>Documento</th>
                  </tr>
                </thead>
                <tbody>
                  {responses.map((item) => (
                    <tr key={item?.id}>
                      <td>{item.customer.email}</td>
                      <td>{item.customer.name}</td>
                      <td>{item?.comment ?? '--'}</td>
                      <td>{item.selectedFeedback}</td>
                      <td>{convertTimestampToDate(item.answeredAt)}</td>
                      <td>{formatDocument(item.customer.document)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {responses.length > 0 && selectedSurvey?.type === '1' && (
            <>
              <table className={styles.table_container}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Nome</th>
                    <th>Data</th>
                    <th>Documento</th>
                    {selectedSurvey?.fields?.map((item) => (
                      <th key={item?.name}>*{item?.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {responses.length > 0 &&
                    responses.map((item) => (
                      <tr key={item?.id}>
                        <td>{item.customer.email}</td>
                        <td>{item.customer.name}</td>
                        <td>{convertTimestampToDate(item.answeredAt)}</td>
                        <td>{formatDocument(item.customer.document)}</td>
                        {item?.fields?.map((list) => (
                          <td key={list?.id}>{list?.value || 'N/A'}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}

          {responses.length > 0 && selectedSurvey?.type === '3' && (
            <>
              <table className={styles.table_container}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Nome</th>
                    <th>Comentário</th>
                    <th>Resposta</th>
                    <th>Data</th>
                    <th>Documento</th>
                  </tr>
                </thead>
                <tbody>
                  {responses.map((item) => (
                    <tr key={item?.id}>
                      <td>{item.customer.email}</td>
                      <td>{item.customer.name}</td>
                      <td>{item?.comment ?? '--'}</td>
                      <td>{item.rating}</td>
                      <td>{convertTimestampToDate(item.answeredAt)}</td>
                      <td>{formatDocument(item.customer.document)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {responses.length === 0 && (
            <p>Não há respostas para esta pesquisa.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SurveyList;
