import { Text, View } from '@react-pdf/renderer';

import { themes } from '../../Themes';
import { getStyleProof } from '../styles';

export function ProofTemplateFooter({ platform, movement }) {
  const theme = themes[platform] || themes.default;
  const styles = getStyleProof(theme);

  return (
    <>
      {movement.method !== '13' ? (
        <View style={styles.section}>
          <View style={styles.separator} />
          <Text style={styles.footerText}>
            Transação efetuada através da instituição de pagamentos Oxpay
          </Text>
        </View>
      ) : (
        ''
      )}
    </>
  );
}
