import { formatDocument } from '../../../../utils/formatDocument';

export const formatExcelData = (list, type) => {
  if (type === '1') {
    return list?.map((item) => ({
      ID: item?.id,
      'ID do Usuário': item?.userId,
      'Documento do Cliente': formatDocument(item?.customer?.document) ?? 'N/A',
      'Nome do Cliente': item?.customer?.name ?? 'N/A',
      Email: item?.customer?.email ?? 'N/A',
      'Data de Resposta': new Date(
        item?.answeredAt?.seconds * 1000,
      ).toLocaleDateString(),
      ...item?.fields?.reduce((acc, field) => {
        acc[field.name] = field?.value || 'N/A';
        return acc;
      }, {}),
    }));
  }
  if (type === '2') {
    return list?.map((item) => ({
      ID: item?.id,
      'ID do Usuário': item?.userId,
      Comentário: item?.comment ?? 'N/A',
      Resposta: item?.selectedFeedback ?? 'N/A',
      'Documento do Cliente': formatDocument(item?.customer?.document) ?? 'N/A',
      'Nome do Cliente': item?.customer?.name ?? 'N/A',
      Email: item?.customer?.email ?? 'N/A',
      'Data de Resposta': new Date(
        item?.answeredAt?.seconds * 1000,
      ).toLocaleDateString(),
    }));
  }

  if (type === '3') {
    return list?.map((item) => ({
      ID: item?.id,
      'ID do Usuário': item?.userId,
      Comentário: item?.comment ?? 'N/A',
      Resposta: item?.rating ?? 'N/A',
      'Documento do Cliente': formatDocument(item?.customer?.document) ?? 'N/A',
      'Nome do Cliente': item?.customer?.name ?? 'N/A',
      Email: item?.customer?.email ?? 'N/A',
      'Data de Resposta': new Date(
        item?.answeredAt?.seconds * 1000,
      ).toLocaleDateString(),
    }));
  }
};
