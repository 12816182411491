import React, { useState } from 'react';
import useSurveys from '../../../hooks/useSurveys';
import { useUser } from '../../../hooks/useUser';
import styles from '../styles.module.scss';

const ModalNPC = () => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const { surveyNPC, setSurveyNPC, respondSurvey, notRespondSurvey } =
    useSurveys();
  const { user, userLogout } = useUser();
  const onClean = () => {
    setRating(null);
    setComment('');
  };
  const onClose = async () => {
    await notRespondSurvey(
      surveyNPC?.survey?.id,
      user?.customer_id,
      surveyNPC?.survey,
    );
    onClean();
    setSurveyNPC({
      open: false,
      survey: false,
    });
  };
  const onLogout = async () => {
    await notRespondSurvey(
      surveyNPC?.survey?.id,
      user?.customer_id,
      surveyNPC?.survey,
    );
    onClean();
    setSurveyNPC({
      open: false,
      survey: false,
    });
    userLogout();
  };

  const handleRatingClick = (value) => {
    setRating(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const id = surveyNPC?.survey?.id;
    const userId = user?.customer?.customer_id;

    const response = {
      userId,
      customer: {
        name: `${user?.customer?.first_name} ${user?.customer?.last_name}`,
        document: user?.customer?.document,
        email: user?.customer?.email,
        date: new Date(),
      },
      rating,
      comment,
    };
    await respondSurvey(id, userId, response);
    onClose();
  };

  return (
    <div
      className={surveyNPC?.open ? styles.modalOverlay : styles.modalOverlay_}
    >
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2 className={styles.title}>Feedback</h2>
        <p className={styles.heading}>{surveyNPC?.survey?.title}</p>
        <p className={styles.description}>{surveyNPC?.survey?.description}</p>
        <div className={styles.ratingContainer}>
          {Array.from({ length: 11 }).map((_, index) => (
            <button
              key={index}
              className={`${styles.ratingButton} ${
                rating === index ? styles.selected : ''
              }`}
              onClick={() => handleRatingClick(index)}
            >
              {index}
            </button>
          ))}
        </div>
        <div className={styles.ratingLabels}>
          <span>Muito Ruim</span>
          <span>Ótimo</span>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <textarea
            className={styles.commentBox}
            placeholder="Adicione um comentário..."
            maxLength={500}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <div className={styles.charCount}>{comment.length}/500</div>
          <div className={styles.wrapper_buttons}>
            <button type="submit" className={styles.submitButton}>
              Enviar
            </button>
            {surveyNPC?.survey?.action === '2' && (
              <button className={styles.submitButton} onClick={onLogout}>
                Sair
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalNPC;
