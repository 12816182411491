import { format, parseISO, subHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatDocument } from '../../../utils/formatDocument';
import { formatMoney } from '../../../utils/formatMoney';
import { getBank, handleBankCodeName } from '../components/Utils';

export const collectedAnticipationSchema = {
  details: [
    {
      object: 'used_consignable_limits',
      field: [
        'finish_date',
        'approved_at',
        'created_at',
        'start_date',
        'date',
        'oldest_pending_date',
      ],
      translate: 'Data de Antecipação',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'used_consignable_limits',
      field: [
        'finish_date',
        'approved_at',
        'created_at',
        'start_date',
        'date',
        'oldest_pending_date',
      ],
      translate: 'Horário',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
    {
      object: 'used_consignable_limits',
      field: 'total_used',
      translate: 'Total Antecipado',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'consignation_collections',
      field: 'amount',
      translate: 'Total Descontado',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'used_consignable_limits',
      field: ['remaining_amount', 'pending_amount'],
      translate: 'Total Pendente',
      handle: (v) => {
        return formatMoney(v);
      },
    },
    {
      object: 'movement',
      field: 'key',
      translate: 'Id da transação',
      handle: (v) => v,
    },
  ],
  destiny: [
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Nome',
      handle: (v) => {
        return handleBankCodeName(v);
      },
    },
    {
      object: 'order',
      field: 'document',
      translate: 'Documento',
      handle: (v) => formatDocument(v),
    },
    {
      object: 'order',
      field: 'bank_code',
      translate: 'Instituição',
      handle: (v) => {
        return getBank(v);
      },
    },
  ],
};
