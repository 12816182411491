export function IconAction({ size = '1.5rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 29"
    >
      <circle
        cx="14"
        cy="14.5"
        r="14"
        fill="#0F4098"
        transform="rotate(-90 14 14.5)"
      ></circle>
      <circle
        cx="14"
        cy="19.526"
        r="1.795"
        fill="#fff"
        transform="rotate(-90 14 19.526)"
      ></circle>
      <circle
        cx="14"
        cy="14.5"
        r="1.795"
        fill="#fff"
        transform="rotate(-90 14 14.5)"
      ></circle>
      <circle
        cx="14"
        cy="9.474"
        r="1.795"
        fill="#fff"
        transform="rotate(-90 14 9.474)"
      ></circle>
    </svg>
  );
}
export function IconCheck({ size = '1.5rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        stroke="#027A48"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.512"
        d="M8.25 12.188l2.063 2.062 3.437-4.813m5.5 2.063a8.25 8.25 0 11-16.5 0 8.25 8.25 0 0116.5 0z"
      ></path>
    </svg>
  );
}
export function IconCancel({ size = '1.5rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        stroke="#B42318"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.512"
        d="M8.564 9.438l3.954 4.124m0-4.124l-3.954 4.124m9.883-2.062a8.56 8.56 0 01-.602 3.157 8.283 8.283 0 01-1.713 2.677 7.894 7.894 0 01-2.565 1.788 7.621 7.621 0 01-3.026.628 7.621 7.621 0 01-3.026-.628 7.893 7.893 0 01-2.565-1.788 8.282 8.282 0 01-1.713-2.677 8.562 8.562 0 01-.602-3.157c0-2.188.833-4.286 2.315-5.834C6.433 4.12 8.444 3.25 10.541 3.25s4.108.87 5.59 2.416c1.483 1.548 2.316 3.646 2.316 5.834z"
      ></path>
    </svg>
  );
}
export function IconExport({ size = '1.5rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        fill="#667085"
        d="M18.563 10.125v8.25a1.375 1.375 0 01-1.375 1.375H4.813a1.375 1.375 0 01-1.375-1.375v-8.25A1.375 1.375 0 014.812 8.75h2.063a.688.688 0 110 1.375H4.812v8.25h12.375v-8.25h-2.062a.687.687 0 110-1.375h2.063a1.375 1.375 0 011.375 1.375zM8.049 6.486l2.264-2.264v7.965a.687.687 0 101.374 0V4.222l2.264 2.264a.688.688 0 00.973-.973l-3.438-3.437a.688.688 0 00-.972 0L7.076 5.513a.688.688 0 00.973.973z"
      ></path>
    </svg>
  );
}
export function IconExportInvoices({ size = '1.5rem', color = '#000000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 24 24"
    >
      <path
        d="M19.5 9a.5.5 0 0 1-.5-.5V7h-3.5a.5.5 0 0 1-.5-.5V3H6.5A1.5 1.5 0 0 0 5 4.5v15A1.5 1.5 0 0 0 6.5 21h11a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 1 1 0v2a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2h8.972a.5.5 0 0 1 .382.146l4 4a.5.5 0 0 1 .146.382V8.5a.5.5 0 0 1-.5.5m-1.207 4h-7.807a.5.5 0 1 1 0-1h7.807l-2.147-2.146a.5.5 0 0 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.707zM16 3.707V6h2.293z"
        data-original="#000000"
      ></path>
    </svg>
  );
}
