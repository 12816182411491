import styles from './styles.module.scss';

export function ConfirmReceiptModal({
  modal,
  handleCloseModal,
  handleReceiptEnable,
  receipt_enabled,
}) {
  return (
    <div
      style={{ visibility: modal ? 'visible' : 'hidden' }}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <h2>Atenção</h2>
        <p>
          Ao confirmar essa operação você está{' '}
          {!receipt_enabled ? 'habilitando' : 'desabilitando'} a permissão dessa
          conta relacionado à gestão de notas.
          <br />
          <br />
          Deseja prosseguir mesmo assim?
        </p>
        <div>
          <button
            className={styles.cancel}
            onClick={() => handleCloseModal(false)}
          >
            Cancelar
          </button>
          <button
            className={styles.button}
            onClick={() => handleReceiptEnable()}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
}
