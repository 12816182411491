export function nameAbbreviate(nome) {
  const palavras = nome.split(' ').filter((v) => v.length && v);

  if (palavras.length < 6) {
    return formatName(nome);
  }

  const nomeAbreviado = palavras.map((palavra, index) => {
    if (index === 0 || index === palavras.length - 1) {
      return palavra;
    } else if (
      palavra.toUpperCase() === 'DA' ||
      palavra.toUpperCase() === 'DE' ||
      palavra.toUpperCase() === 'DO'
    ) {
      return '';
    }

    return palavra[0] + '.';
  });

  return formatName(nomeAbreviado.join(' '));
}

export function formatName(fullName) {
  return fullName
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function formatContractName(fullName, maxLength = 30) {
  const formattedName = fullName
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedName.length > maxLength
    ? formattedName.slice(0, maxLength - 3) + '...'
    : formattedName;
}

export function formatNameExtractCompany(companyName) {
  return companyName
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .map((a, i) =>
      i === 0
        ? a
        : i === companyName.split(' ').length - 1
        ? a
        : a[0] + '.'.toUpperCase(),
    )
    .join(' ');
}
