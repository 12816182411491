import React, { useState } from 'react';
import useSurveys from '../../../hooks/useSurveys';
import { useUser } from '../../../hooks/useUser';
import styles from '../styles.module.scss';

const ModalCSAT = () => {
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [comment, setComment] = useState('');
  const { user, userLogout } = useUser();

  const { surveyCSAT, setSurveyCSAT, respondSurvey, notRespondSurvey } =
    useSurveys();
  const feedbackOptions = [
    { emoji: '😢', label: 'Muito Ruim' },
    { emoji: '🙁', label: 'Ruim' },
    { emoji: '😐', label: 'Regular' },
    { emoji: '🙂', label: 'Bom' },
    { emoji: '😁', label: 'Ótimo' },
  ];
  const onClean = () => {
    setSelectedFeedback(null);
    setComment('');
  };
  const onClose = async () => {
    await notRespondSurvey(
      surveyCSAT?.survey?.id,
      user?.customer_id,
      surveyCSAT?.survey,
    );
    onClean();
    setSurveyCSAT({
      open: false,
      survey: false,
    });
  };
  const onLogout = async () => {
    await notRespondSurvey(
      surveyCSAT?.survey?.id,
      user?.customer_id,
      surveyCSAT?.survey,
    );
    onClean();
    setSurveyCSAT({
      open: false,
      survey: false,
    });
    userLogout();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const id = surveyCSAT?.survey?.id;
    const userId = user?.customer?.customer_id;

    const response = {
      userId,
      customer: {
        name: `${user?.customer?.first_name} ${user?.customer?.last_name}`,
        document: user?.customer?.document,
        email: user?.customer?.email,
        date: new Date(),
      },
      selectedFeedback,
      comment,
    };
    await respondSurvey(id, userId, response);
    onClose();
  };

  return (
    <div
      className={surveyCSAT?.open ? styles.modalOverlay : styles.modalOverlay_}
    >
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2 className={styles.title}>Feedback</h2>
        <p className={styles.subtitle}>{surveyCSAT?.survey?.title}</p>
        <p className={styles.description}>{surveyCSAT?.survey?.description}</p>
        <div className={styles.feedbackOptions}>
          {feedbackOptions.map((option, index) => (
            <button
              key={index}
              className={`${styles.feedbackOption} ${
                selectedFeedback === option.label ? styles.selected : ''
              }`}
              onClick={() => setSelectedFeedback(option.label)}
            >
              <span role="img" aria-label={option.label}>
                {option.emoji}
              </span>
            </button>
          ))}
        </div>
        {selectedFeedback && (
          <div className={styles.selectedFeedbackLabel}>{selectedFeedback}</div>
        )}
        <form onSubmit={handleSubmit} className={styles.form}>
          <textarea
            className={styles.commentBox}
            placeholder="Adicione um comentário..."
            maxLength={500}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <div className={styles.charCount}>{comment.length}/500</div>
          <div className={styles.wrapper_buttons}>
            <button type="submit" className={styles.submitButton}>
              Enviar
            </button>
            {surveyCSAT?.survey?.action === '2' && (
              <button className={styles.submitButton} onClick={onLogout}>
                Sair
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalCSAT;
