export function formatGroup(dados) {
  if (!dados.length) return [];

  const agrupado = dados.reduce((acc, item) => {
    const data = item.date.split(' ')[0];

    if (!acc[data]) {
      acc[data] = {
        date: data,
        before: item.before,
        after: item.after,
        transactions: [],
      };
    }

    acc[data].transactions.push({ ...item });
    return acc;
  }, {});

  return Object.values(agrupado);
}
