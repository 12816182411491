import { addDays, differenceInDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { IoReloadOutline } from 'react-icons/io5';
import { RiSearch2Line } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { CheckPicker, Loader } from 'rsuite';
import { DatePicker } from '../../components/DatePiker';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { useDate } from '../../hooks/useDate';
import { useNotification } from '../../hooks/useNotifications';
import { usePagination } from '../../hooks/usePagination';
import { useFetchInvoiceQuery } from '../../hooks/useQuery';
import { useTransfer } from '../../hooks/useTransfer';
import { useUser } from '../../hooks/useUser';
import { lower } from '../../utils/lower';
import { Rejected } from './Components/CardAmountAwait';
import { Approved } from './Components/CardAmountMoney';
import { WaitingApproval } from './Components/CardAmountPeople';
import { CardResumeSelect } from './Components/CardResumeSelect';
import { InputSearch } from './Components/InputSearch';
import { statusData } from './data';
import styles from './styles.module.scss';

import { useInvoice } from '../../hooks/useInvoice';
import { ButtonMenuActions } from './Components/ButtonMenuActions';
import InvoiceTable from './Components/InvoiceTable';

const Invoice = () => {
  const [search, setSearch] = useState(false);
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();
  const {
    dateInvoice,
    setDateInvoice,
    dateRangerInvoice,
    setDateRangerInvoice,
  } = useDate();

  const { statusTransferSelect, setStatusTransferSelect } = useTransfer();
  const { user } = useUser();

  const history = useHistory();
  const [listSelect, setListSelect] = useState([]);
  const [firstClick, setFirstClick] = useState(true);
  const [loading, setLoading] = useState(false);
  const { fetchReceiptKeyApprove, fetchReceiptKeyRefuse } = useInvoice();

  useEffect(() => {
    if (search) {
      setListSelect([]);
      setFirstClick([]);
      setStatusTransferSelect([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (
      !dateInvoice ||
      differenceInDays(new Date(dateInvoice[1]), new Date(dateInvoice[0])) < 90
    ) {
      let startDateString = addDays(dateInvoice[0], 1)
        .toISOString()
        .substring(0, 10);

      let endDateString = addDays(dateInvoice[1], 1)
        .toISOString()
        .substring(0, 10);

      let startDate = new Date(startDateString);

      startDate.setHours(-3, 0, 0);
      startDate = startDate.toISOString();

      let endDate = new Date(endDateString);

      endDate.setHours(20, 59, 59);
      endDate = endDate.toISOString();

      setDateRangerInvoice({
        dateInitial: startDate.toString().substring(0, 19) + '.0793547-03:00',
        dateFinally: endDate.toString().substring(0, 19) + '.0793547-03:00',
      });

      handleReload();
    } else {
      addNotification(
        'A data não pode ter intervalo maior que 90 dias ',
        1,
        'Atenção',
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInvoice]);

  const handleStatusChange = (value) => {
    setStatusTransferSelect(value);
  };

  const { data, isFetching } = useFetchInvoiceQuery(
    !!dateRangerInvoice ? dateRangerInvoice : null,
  );

  useEffect(() => {
    if (search) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function handleReload() {
    setListSelect([]);
    setFirstClick(true);
    await queryClient.invalidateQueries(['InvoiceList']);
  }

  function handlePushRouter(id, status) {
    history.push({
      pathname: `/menu/order/${id}`,
      state: {
        status,
      },
    });
  }
  const dataCards = data ?? [];

  const filterList = data
    ? data
        .filter((item) => {
          if (statusTransferSelect.length === 0) return item;

          return statusTransferSelect.includes(item.status.id) && item;
        })
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item?.source_company['accountable']}`).includes(
            lower(search),
          );
        })
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item?.source_company['cnpj']}`).includes(
            lower(search),
          );
        })
    : [];
  const toggleSelect = (itemId) => {
    if (listSelect.includes(itemId)) {
      setListSelect(listSelect.filter((id) => id !== itemId));
    } else {
      setListSelect([...listSelect, itemId]);
    }
  };
  const selectAll = () => {
    const ids = filterList.map((item) => item.key);

    if (firstClick) {
      setListSelect([]);
      setListSelect(ids);
    } else {
      setListSelect([]);
    }

    // Toggle the firstClick state
    setFirstClick(!firstClick);
  };
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    !!filterList ? filterList : [],
    10,
    '/menu/invoice',
  );
  const resume_listSelect =
    listSelect.length > 0 &&
    listSelect
      .map((item) => {
        return filterList.find((order) => order.key === item);
      })
      .reduce(
        (acc, item) => {
          return {
            total_amount: acc.total_amount + item?.amount,
            total: acc.total + 1,
          };
        },
        { total_amount: 0, total: 0 },
      );
  async function handleReceiptApprove() {
    setLoading({ approve: true, cancel: false });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          await fetchReceiptKeyApprove(key);
          successCount++;

          if (listSelect.length === 1) {
            addNotification(
              'Nota aprovada com sucesso!',
              3,
              'Processamento de aprovação',
              true,
            );
          }
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error.message);

          if (listSelect.length === 1) {
            addNotification(
              error.message.includes('403') ||
                error.message.includes('capability')
                ? 'Você não tem permissão.'
                : 'Não foi possível aprovar a nota.',
              2,
              'Processamento de aprovação',
              true,
            );
          }
        }
      }
    } catch (error) {
      console.error('Erro geral no processamento das notas:', error);
    } finally {
      if (listSelect.length > 1) {
        addNotification(
          <>
            Processamento finalizado! <br />
            Sucessos: {successCount}, Erros: {errorCount}.
          </>,
          errorCount > 0 ? 1 : successCount < 1 ? 2 : 3,
          'Processamento de aprovação',
          true,
        );
      }

      setLoading(false);
      handleReload();
      setListSelect([]);
    }
  }
  async function handleReceiptRefuse() {
    setLoading({ approve: false, cancel: true });
    let successCount = 0;
    let errorCount = 0;

    try {
      for (const key of listSelect) {
        try {
          await fetchReceiptKeyRefuse(key);
          successCount++;

          if (listSelect.length === 1) {
            addNotification(
              'Nota rejeitada com sucesso!',
              3,
              'Processamento de cancelamento',
              true,
            );
          }
        } catch (error) {
          errorCount++;
          console.error(`Erro ao processar a chave ${key}:`, error);

          if (listSelect.length === 1) {
            addNotification(
              error.message.includes('403') ||
                error.message.includes('capability')
                ? 'Você não tem permissão.'
                : 'Não foi possível rejeitar a nota.',
              2,
              'Processamento de cancelamento',
              true,
            );
          }
        }
      }
    } catch (globalError) {
      console.error('Erro geral no processamento das notas:', globalError);
    } finally {
      setLoading(false);
      handleReload();
      setListSelect([]);

      if (listSelect.length > 1) {
        addNotification(
          <>
            Processamento finalizado! <br />
            Sucessos: {successCount}, Erros: {errorCount}.
          </>,
          errorCount > 0 ? 1 : successCount < 1 ? 2 : 3,
          'Processamento de cancelamento',
          true,
        );
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header_style_}>
        <div className={styles.title_style}>Gestão de Notas</div>
      </div>
      <div className={styles.container_arrow_cards}>
        <div className={styles.wrapper_cards_resume}>
          <WaitingApproval
            data={dataCards}
            loading={isFetching}
            title={'Aguardando Aprovação'}
          />
          <Approved data={dataCards} loading={isFetching} title={'Aprovadas'} />
          <Rejected
            data={dataCards}
            loading={isFetching}
            title={'Reprovadas'}
          />
        </div>
      </div>
      <div className={styles.content_list}>
        <div className={styles.title_list}>
          <header>
            <h3>Lista de Notas Fiscais</h3>
          </header>
          <InputSearch
            label="Pesquisar (Nome, número, CPF / CNPJ)"
            type="text"
            setValue={setSearch}
            value={search}
            Icon={RiSearch2Line}
            width="50%"
            disabled={isFetching}
          />
          <div className={styles.container_arrow_box}>
            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
        <div className={styles.wrapper_content}>
          <div className={styles.selectReload}>
            <div className={styles.wrapper_buttons_select}>
              <DatePicker
                setDate={setDateInvoice}
                type={4}
                style={{ width: '16rem' }}
                disabled={isFetching}
                value={dateInvoice}
              />
              <CheckPicker
                data={isFetching ? [] : statusData}
                placeholder="Status"
                onChange={handleStatusChange}
                disabled={isFetching}
                cleanable={isFetching}
                defaultValue={statusTransferSelect}
                value={statusTransferSelect}
              />
            </div>
            <div className={styles.wrapper_acions}>
              {resume_listSelect?.total > 0 && (
                <>
                  <CardResumeSelect resume={resume_listSelect} />
                  <button
                    onClick={handleReceiptApprove}
                    className={styles.send_approve}
                  >
                    {loading.approve ? (
                      <Loader />
                    ) : (
                      <FiCheck size="1.125rem" color="var(--white)" />
                    )}
                    Aprovar
                  </button>
                </>
              )}
              {data?.length > 0 && (
                <ButtonMenuActions
                  invoices={data}
                  listSelect={listSelect}
                  handleReceiptApprove={handleReceiptApprove}
                  handleReceiptRefuse={handleReceiptRefuse}
                />
              )}
            </div>
            <span className={styles.arrow}>
              {[16].every((item) => user?.permission.includes(item)) && (
                <button
                  disabled={!isFetching ? false : true}
                  className={styles.button_load_style}
                  onClick={handleReload}
                >
                  {isFetching ? (
                    <Loader />
                  ) : (
                    <IoReloadOutline size="1.3rem" color="var(--white)" />
                  )}
                </button>
              )}
            </span>
          </div>

          <div className={styles.wrapper_table}>
            <InvoiceTable
              invoices={currentItens}
              isFetching={isFetching}
              handlePushRouter={handlePushRouter}
              selectAll={selectAll}
              firstClick={firstClick}
              toggleSelect={toggleSelect}
              listSelect={listSelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
