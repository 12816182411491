import { format, parseISO, subHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatCardLastNumbers } from '../../../utils/formatCard';
import { formatStatus } from '../../../utils/formatStatus';

export const cardSchema = {
  details: [
    {
      object: 'movement',
      field: 'status',
      translate: 'Status',
      handle: (v) => {
        return formatStatus(v).text;
      },
    },
    {
      object: 'card',
      field: ['last4_digits', 'card_id'],
      translate: 'Cartão',
      handle: (v) => {
        return v.length > 1 ? formatCardLastNumbers(v) : v;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Data',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(
          subHours(approved_at, 3),
          'EEEE, dd/MM/yyyy',
          {
            locale: ptBR,
          },
        );

        return formattedApprovedAt;
      },
    },
    {
      object: 'movement',
      field: ['finish_date', 'approved_at', 'created_at', 'start_date', 'date'],
      translate: 'Hora',
      handle: (v) => {
        const approved_at = parseISO(v);
        const formattedApprovedAt = format(subHours(approved_at, 3), 'HH:mm', {
          locale: ptBR,
        });

        return formattedApprovedAt;
      },
    },
  ],
};
