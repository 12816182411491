export const actionsSurveys = [
  {
    id: '1',
    description: 'Login',
  },
  {
    id: '2',
    description: 'Logout',
  },
  {
    id: '3',
    description: 'Pagamento em Lote (Criar) ',
  },
  {
    id: '4',
    description: 'Pagamento em Lote (Finalizar) ',
  },
  {
    id: '5',
    description: 'Pagamento  Individual (Criar) ',
  },
  {
    id: '6',
    description: 'Pagamento  Individual (Finalizar)',
  },
  {
    id: '7',
    description: 'Antecipação em Lote (Criar)',
  },
  {
    id: '8',
    description: 'Antecipação em Lote (Finalizar)',
  },
  {
    id: '9',
    description: 'Antecipação  Individual (Criar)',
  },
  {
    id: '10',
    description: 'Antecipação  Individual (Finalizar)',
  },
  {
    id: '11',
    description: 'Cadastros (Criar)',
  },
  {
    id: '12',
    description: 'Cadastros (Finalizar)',
  },
];

export const typesSurveys = [
  {
    id: '1',
    description: 'Formulário',
  },
  {
    id: '2',
    description: 'Satisfação (CSAT)',
  },
  {
    id: '3',
    description: 'Coleta de Notas (NPS)',
  },
];

export const frequencySurveys = Array.from({ length: 14 }, (_, index) => ({
  id: index + 1,
  value: index + 1,
}));

export const options = ['Muito Ruim', 'Ruim', 'Regular', 'Bom', 'Ótimo'];
