import { useInfiniteQuery, useQuery } from 'react-query';
import { useCustomers } from './useCustomers';
import { useDate } from './useDate';
import { useInvoice } from './useInvoice';
import { useOrder } from './useOrder';
import { useUser } from './useUser';

const DEFAULT_STALE_TIME = 1000 * 60 * 10;
const DEFAULT_OPTIONS = {
  staleTime: DEFAULT_STALE_TIME,
  refetchOnMount: true,
  keepPreviousData: true,
};

export const handleReload = async ({
  queryData,
  setLoading,
  setValue,
  checkKey,
  checkValue,
  onDoesntExists,
}) => {
  try {
    setLoading(true);

    const data = queryData?.allBalance.find(
      (item) => String(item[checkKey]) === checkValue,
    );
    if (!data) {
      onDoesntExists();
      return;
    }
    setValue(data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const useConsignableCollaboratorQuery = () => {
  const { fetchGetListConsignable } = useUser();

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      ['ConsignableCollaborator'],
      ({ pageParam = { onboarding: 0, linked: 0 } }) =>
        fetchGetListConsignable({
          pageParamOnboarding: pageParam.onboarding,
          pageParamLinked: pageParam.linked,
        }),
      {
        getNextPageParam: (lastPage) => {
          const nextOnboarding = lastPage.nextPageOnboarding;
          const nextLinked = lastPage.nextPageLinked;

          if (!nextOnboarding && !nextLinked) {
            return undefined;
          }

          return {
            onboarding: nextOnboarding ?? 0,
            linked: nextLinked ?? 0,
          };
        },
      },
    );

  const allData = data?.pages.length
    ? Array.from(
        data?.pages
          .flatMap((page) => page.data)
          .reduce((map, item) => {
            map.set(item.id, item);

            return map;
          }, new Map()),
      ).map((v) => v[1])
    : [];

  return {
    data: allData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};

export const useCollaboratorQuery = (id) => {
  const { fetchCollaborator } = useUser();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    ['keyCollaborator', id],
    () => fetchCollaborator(id),
    DEFAULT_OPTIONS,
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};

export const useConsignableLinkedQuery = () => {
  const { fetchGetListLinked } = useUser();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    'ConsignableLinked',
    () => fetchGetListLinked(),
    DEFAULT_OPTIONS,
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};

export const useCustomerListDataQuery = () => {
  const { fetchCustomers } = useCustomers();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery('customerListData', () => fetchCustomers(), DEFAULT_OPTIONS);

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};
export const useFetchOrderQuery = (body, time) => {
  const { fetchDataListOrder } = useOrder();
  const { dateRanger } = useDate();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    ['OrdersList', dateRanger],
    () => fetchDataListOrder(body ?? dateRanger),
    { ...DEFAULT_OPTIONS, scaleTime: time },
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};

export const useFetchInvoiceQuery = (body, time) => {
  const { fetchDataListInvoice } = useInvoice();
  const { dateRangerInvoice } = useDate();
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    ['InvoiceList', dateRangerInvoice],
    () => fetchDataListInvoice(body ?? dateRangerInvoice),
    { ...DEFAULT_OPTIONS, scaleTime: time },
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};

export const useFetchMovementsBankSlip = (body, time) => {
  const { fetchMovementList } = useOrder();
  const { dateRanger } = useDate();
  const dateSince = body.dateInitial ?? dateRanger.dateInitial;
  const dateUntil = body.dateFinally ?? dateRanger.dateFinally;
  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    ['bankSlip', dateRanger],
    () => fetchMovementList('BankSlip', dateSince, dateUntil),
    { ...DEFAULT_OPTIONS, scaleTime: time },
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};
export const useAllBalancesQuery = (value) => {
  const { fetchGetAllBalances, user } = useUser();

  const {
    data,
    isFetching,
    refetch: triggerRefetch,
  } = useQuery(
    [
      'Companies',
      [0].every((item) => user?.permission.includes(item)) && value,
    ],
    () => fetchGetAllBalances(value),
    DEFAULT_OPTIONS,
  );

  return {
    data,
    isFetching,
    triggerRefetch,
  };
};
