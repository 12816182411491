import { Document, Page, View } from '@react-pdf/renderer';
import { ProofTemplateAnticipation } from './Anticipation';
import { ProofTemplateDestiny } from './Destiny';
import { ProofTemplateDetails } from './Details';
import { ProofTemplateFooter } from './Footer';
import { ProofTemplateHeader } from './Header';
import { ProofTemplateOrigin } from './Origin';

import { themes } from '../Themes';
import { getStyleProof } from './styles';

export default function ProofTemplate({ platform, proof, movement }) {
  const theme = themes[platform] || themes.default;
  const styles = getStyleProof(theme);

  return (
    <Document>
      <Page
        size={{ width: platform !== 'web' ? 380 : 500, height: 'auto' }}
        style={styles.page}
      >
        <View style={styles.proof}>
          <ProofTemplateHeader
            platform={platform}
            proof={proof}
            movement={movement}
          />
          <ProofTemplateAnticipation
            platform={platform}
            proof={proof}
            movement={movement}
          />
          <ProofTemplateDetails
            platform={platform}
            proof={proof}
            movement={movement}
          />
          <ProofTemplateDestiny platform={platform} proof={proof} />
          <ProofTemplateOrigin platform={platform} proof={proof} />
          <ProofTemplateFooter platform={platform} movement={movement} />
        </View>
      </Page>
    </Document>
  );
}
