import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { Loader } from 'rsuite';
import * as yup from 'yup';
import { useUser } from '../../../../../../hooks/useUser';
import { formatMoney } from '../../../../../../utils/formatMoney';
import { IconLike, IconMoney, IconPerson, IconProof } from './../icons';
import FormGroup from './FormGroup';
import styles from './styles.module.scss';

import { validateCNPJ, validateCPF } from 'validations-br';
import { useOrder } from '../../../../../../hooks/useOrder';
import { parseCurrencyToNumber } from './utils/parseCurrencyToNumber';

import { useNotification } from '../../../../../../hooks/useNotifications';
import { usePayment } from '../../../../../../hooks/usePayment';
import { addHoursAndFormat } from './utils/addHoursAndFormat';

import { formatDate } from '../../../../../../utils/formatDate';
import { formatDocument } from '../../../../../../utils/formatDocument';
import { IconBook, IconDate, IconDocument, IconValue } from './icons';
import { convertDate } from './utils/convertDate';

const Validate = yup.object().shape({
  document: yup
    .string()
    .typeError('O campo deve receber um documento Valido.')
    .test('is-document', 'Documento não é valido', function (value) {
      return validateCPF(value) || validateCNPJ(value);
    })
    .required('Chave é obrigatória'),
  description: yup.string().required('Descrição é obrigatória'),
  amount: yup
    .string()
    .typeError('O campo deve receber um valor.')
    .required('Valor é obrigatório')
    .test(
      'is-amount',
      'O campo deve receber um valor em real valido e maior que R$ 0,01.',
      (value) => {
        const regex = /^(\d{1,3}(?:\.\d{3})*(?:,\d{2})?|\d+(?:,\d{2})?)$/;

        const testRegex = regex.test(value);

        const numericValue = parseFloat(
          value.replace(/[.,]/g, '').replace(',', '.'),
        );

        if (!isNaN(numericValue) && numericValue >= 0.01 && testRegex) {
          return true;
        } else {
          return false;
        }
      },
    ),
  is_salary: yup.string().notRequired(),
  date: yup
    .date()
    .required('Data é obrigatória')
    .typeError('O campo deve receber uma data válida.')
    .when('expiration_date', (expiration_date, schema) =>
      expiration_date
        ? schema.max(
            new Date(expiration_date),
            'A data deve ser menor que a data de expiração.',
          )
        : schema,
    ),
  expiration_date: yup
    .date()
    .required('Data de pagamento é obrigatória')
    .typeError('O campo deve receber uma data de pagamento válida.'),
});
function addOneDayToCurrentDate() {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toISOString().split('T')[0];
}
const PaymentTransfer = () => {
  const {
    stepPayment,
    setStepPayment,
    payment,
    setPayment,
    finishPayment,
    setFinishPayment,
    clearStatePayment,
    loading,
    setLoading,
    setErrosSteps,
  } = usePayment();

  const today = new Date().toISOString().split('T')[0];
  const tomorrow = addOneDayToCurrentDate();
  const { addNotification } = useNotification();

  const [clear] = useState(false);
  const { user } = useUser();

  const userIsAdmin = [13, 10, 12, 14].every((item) =>
    user?.permission.includes(item),
  );

  const {
    fetchTransferConsignment,
    fetchOrderKeyValidation,
    fetchOrderKeyCancel,
    fetchOrderKey,
    fetchOrderPreview,
  } = useOrder();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(Validate),
    defaultValues: {
      date: payment?.date
        ? convertDate(payment?.date)
        : new Date().toISOString().split('T')[0],
      expiration_date: payment?.expiration_date
        ? convertDate(
            formatDate(payment?.expiration_date, "dd/MM/yyyy '-' HH:mm:ss"),
          )
        : new Date().toISOString().split('T')[0],
      document: payment?.document ? payment?.document.replace(/\D/g, '') : '',
      amount: payment?.amount ? payment?.amount : '',
      description: payment?.description || 'Antecipação',
      is_salary: payment?.is_salary || false,
    },
  });

  const formDate = watch('date');
  const formExpirationDate = watch('expiration_date');

  const onBackStepCancelOrder = async () => {
    try {
      setLoading({ paymentOrderCancel: true });
      if (payment) {
        if (payment.status.id !== 7) {
          await fetchOrderKeyCancel(payment.key, 3);
        }
      }
      setStepPayment(1);
    } catch (error) {
      console.log('🚀 error:', error);
    }
  };
  const onSubmit = async (values) => {
    try {
      setLoading({ paymentOrder: true });

      const { document, amount, date, description, expiration_date } = values;

      const data = await fetchTransferConsignment({
        body: {
          description: description,
          movements: [
            {
              additional_data: description,
              document: document,
              amount: parseCurrencyToNumber(amount),
              expiration_date: addHoursAndFormat(expiration_date, 3),
              date: addHoursAndFormat(date, 3),
            },
          ],
        },

        isManual: true,
      });

      if (!!data) {
        await fetchOrderPreview(data?.key);
        const { movements, order } = await fetchOrderKey(data.key);

        if (movements[0]) {
          const paymentInfo = {
            ...movements[0],
            amount: formatMoney(movements[0]?.amount, 'code'),
            name: movements[0]?.name,
            bank_code: movements[0].bank_code ?? 'OX PAY',
            bank_name: 'OX PAY',
            document: movements[0].document,
            date: movements[0].date,
            expiration_date: movements[0].expiration_date,
            description: movements[0].additional_data,
            key: order?.key,
            orderKey: order?.key,
          };

          setPayment(paymentInfo);
          setStepPayment(2);
          setErrosSteps({
            status: 'process',
          });
        }
      } else {
        setPayment({ document, amount, date, description });
        setErrosSteps({
          step: 1,
          status: 'error',
          message: 'Documento não encontrado',
        });

        addNotification(
          <>
            Não foi encontrado dados do documento informado! <br />
            Por favor, verifique o CPF/CNPJ e tente novamente.
          </>,
          2,
          'Conta não encontrada',
          true,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onPayment = async () => {
    setLoading({ paymentOrder: true });

    try {
      if (payment.key) {
        await fetchOrderKeyValidation(payment.key, 3, '10');
        const { movements } = await fetchOrderKey(payment?.key);

        if (payment) {
          setFinishPayment({
            ...movements[0],
            key: payment.key,
            orderKey: payment.orderKey,
          });
          setStepPayment(3);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      {stepPayment === 1 && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_wrapper}>
            <FormGroup
              name="document"
              label={
                <>
                  <strong>Documento </strong> (CPF / CNPJ):
                </>
              }
              type="text"
              data={{
                value: payment?.document
                  ? payment?.document
                  : user.customer?.company_id === 4
                  ? '38927293000120'
                  : '',
              }}
              placeholder={'999.999.999-99'}
              disabled={!loading?.paymentOrder ? false : true}
              mask={['999.999.999-99', '99.999.999/9999-99']}
              register={register}
              errors={errors}
              setValue={setValue}
              clean={clear}
              Icon={IconDocument}
            />
            <FormGroup
              name="amount"
              label={
                <>
                  <strong>Valor: </strong>
                </>
              }
              data={{ value: payment?.amount }}
              type="text"
              mask={[
                '9,99',
                '99,99',
                '999,99',
                '9.999,99',
                '99.999,99',
                '999.999,99',
                '9.999.999,99',
              ]}
              placeholder="R$ 0,00"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              errors={errors}
              setValue={setValue}
              Icon={IconValue}
            />
            <FormGroup
              name="expiration_date"
              label={
                <>
                  <strong>Data do Pagamento: </strong>{' '}
                  {tomorrow === formExpirationDate ? 'Amanhã' : 'Agendado'}
                </>
              }
              type="date"
              placeholder="Data"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              min={addOneDayToCurrentDate()}
              errors={errors}
              setValue={setValue}
              data={{
                value: payment?.expiration_date
                  ? convertDate(
                      formatDate(
                        payment?.expiration_date,
                        "dd/MM/yyyy '-' HH:mm:ss",
                      ),
                    )
                  : addOneDayToCurrentDate(),
              }}
              Icon={IconDate}
            />
          </div>

          <div className={styles.form_wrapper_}>
            <FormGroup
              name="date"
              label={
                <>
                  <strong>Data de Liberação: </strong>{' '}
                  {today === formDate ? 'Hoje' : 'Agendado'}
                </>
              }
              type="date"
              placeholder="Data"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              min={new Date().toISOString().split('T')[0]}
              errors={errors}
              setValue={setValue}
              data={{
                value: payment?.date
                  ? convertDate(payment.date)
                  : new Date().toISOString().split('T')[0],
              }}
              Icon={IconDate}
            />
            <FormGroup
              name="description"
              label={
                <>
                  <strong>Descrição: </strong>
                </>
              }
              type="text"
              placeholder="Pagamento"
              disabled={!loading.paymentOrder ? false : true}
              register={register}
              data={{ value: payment?.description ?? 'Antecipação' }}
              errors={errors}
              setValue={setValue}
              Icon={IconBook}
            />

            <div className={styles.buttons_form}>
              <button
                type="button"
                disabled={loading.paymentOrder}
                className={styles.button_cancel}
                onClick={clearStatePayment}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={styles.button}
                disabled={loading.paymentOrder}
              >
                Confirmar
                {loading.paymentOrder && <Loader size="xs " />}
              </button>
            </div>
          </div>
        </form>
      )}

      {stepPayment === 2 && (
        <div className={styles.description}>
          <h1 className={styles.title}>Foi criada uma antecipação para:</h1>
          {payment && (
            <>
              <div className={styles.description_payment}>
                <div className={styles.description}>
                  <div className={styles.header_info}>
                    <div className={styles.icon}>
                      <IconPerson />
                    </div>

                    <div className={styles.info}>
                      <div className={styles.title_info}>Colaborador:</div>
                      {payment.name === '---' ? (
                        <div className={styles.preview}>
                          * Dados não carregados
                        </div>
                      ) : (
                        <div className={styles.title_description}>
                          {payment.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className={styles.line_info} />
                  <div className={styles.header_info_wrapper}>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconMoney />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>Valor:</div>

                        <div className={styles.title_description}>
                          {formatMoney(parseCurrencyToNumber(payment.amount))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>
                          Data de Liberação:
                        </div>

                        <div className={styles.title_description}>
                          {payment.date}
                        </div>
                      </div>
                    </div>
                    <div className={styles.header_info}>
                      <div className={styles.icon}>
                        <IconDate />
                      </div>

                      <div className={styles.info}>
                        <div className={styles.title_info}>
                          Data de Pagamento:
                        </div>

                        <div className={styles.title_description}>
                          {formatDate(payment.expiration_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.box_info}>
                    <span>
                      Documento: <p> {formatDocument(payment.document)} </p>
                    </span>

                    {payment?.error_description && (
                      <div className={styles.error_preview}>
                        {payment.error_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.block} />
                <div className={styles.values_container}>
                  <div className={styles.values}>
                    <IconProof />
                    <span className={styles.title_amount}>Valor Total:</span>
                    <span className={styles.amount}>
                      {formatMoney(parseCurrencyToNumber(payment.amount))}
                    </span>
                  </div>

                  <div className={styles.buttons}>
                    <button
                      type="button"
                      className={styles.button_cancel}
                      disabled={!loading.paymentOrder ? false : true}
                      onClick={onBackStepCancelOrder}
                    >
                      {' '}
                      {loading.paymentOrderCancel && <Loader size="xs " />}
                      Cancelar
                    </button>
                    {payment.status.id !== 7 && (
                      <button
                        type="button"
                        className={styles.button}
                        disabled={!loading.paymentOrder ? false : true}
                        onClick={onPayment}
                      >
                        Confirmar
                        {loading.paymentOrder && <Loader size="xs " />}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {stepPayment === 3 && (
        <div className={styles.description}>
          {finishPayment && (
            <>
              <div className={styles.description_finish}>
                <IconLike />
                <h2>
                  {userIsAdmin
                    ? 'Antecipação finalizada!'
                    : 'Antecipação Criada!'}
                </h2>
                <p>
                  {userIsAdmin ? (
                    <>
                      Tudo certo até aqui, agora você pode <br /> acompanhar sua
                      antecipação.
                    </>
                  ) : (
                    <>
                      Tudo certo até aqui, <br /> agora é só esperar aprovação
                      dessa antecipação.
                    </>
                  )}
                </p>
                <div className={styles.buttons_finish}>
                  <button
                    type="button"
                    disabled={loading.paymentOrder}
                    className={styles.button_cancel}
                    onClick={clearStatePayment}
                  >
                    <IoMdClose />
                    Fechar
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { PaymentTransfer };
