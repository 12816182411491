import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

async function exportInvoices(invoices) {
  const zip = new JSZip();
  let hasFiles = false;
  let successCount = 0;
  let errorCount = 0;

  for (const invoice of invoices) {
    try {
      const response = await fetch(invoice.url);
      if (!response.ok) {
        console.error(
          `Failed to fetch image for invoice ${invoice.key}: ${response.statusText}`,
        );
        continue;
      }
      const blob = await response.blob();
      zip.file(`${invoice.key}.jpg`, blob);
      hasFiles = true;
      successCount++;
    } catch (error) {
      errorCount++;
      console.error(`Error fetching image for invoice ${invoice.key}:`, error);
    }
  }

  if (!hasFiles) {
    throw new Error('No images were downloaded, zip file will not be created.');
  }

  try {
    const today = format(new Date(), 'dd-MM-yyyy_HH-mm-ss');
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `Selecao_NFS-${today}.zip`);
  } catch (error) {
    console.error('Error generating zip file:', error);
    throw new Error('Failed to generate zip file.');
  }

  return { successCount, errorCount };
}

export default exportInvoices;
