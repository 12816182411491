import { format } from 'date-fns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function exportExcelInvoices(invoices) {
  const data = invoices.map((item) => {
    return {
      Chave: item?.key,
      Número: item?.invoice_number,
      Nome: item?.source_company?.social_reason,
      CNPJ: item?.source_company?.cnpj,
      Valor: parseFloat(item?.amount).toFixed(2) || 0.0, // Convertendo para número decimal
      CNAE: item?.cnae,
      Status: item?.status?.text,
      Data: item?.created_at
        ? format(new Date(item.created_at), 'dd/MM/yyyy')
        : '', // Formatando para data
    };
  });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const today = format(new Date(), 'dd-MM-yyyy_HH-mm-ss');
    const ws = XLSX.utils.json_to_sheet(csvData);

    // Aplicando estilo de negrito para o cabeçalho e primeira linha
    const boldStyle = { font: { bold: true } };
    const range = XLSX.utils.decode_range(ws['!ref']);

    for (let row = 0; row <= 1; row++) {
      // Cabeçalho e primeira linha
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        if (!ws[cellAddress]) continue;
        ws[cellAddress].s = boldStyle;
      }
    }

    // Configuração do formato de colunas
    ws['!cols'] = [
      { wch: 25 }, // Chave
      { wch: 12 }, // Número
      { wch: 30 }, // Nome
      { wch: 18 }, // CNPJ
      { wch: 12, z: '#,##0.00' }, // Valor formatado como decimal
      { wch: 12 }, // CNAE
      { wch: 10 }, // Status
      { wch: 12, z: 'dd/MM/yyyy' }, // Data formatada
    ];

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + today + fileExtension);
  };

  exportToCSV(data, 'relatorio_notas_fiscais_');
}
