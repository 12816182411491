export const themes = {
  default: {
    backgroundColor: '#f7f7fa',
    textColor: '#001A5D',
    primaryColor: '#001A5D',
  },
  faster_log: {
    backgroundColor: '#f7f7fa',
    primaryColor: '#0000A2',
    secondColor: '#0153E7',
    textColor: '#1A1C1F',
  },
  abc_cargas: {
    backgroundColor: '#f7f7fa',
    abcCargas: '#ED1B23',
    secondColor: '#E96166',
    primaryColor: '#221E1F',
  },
};
